import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import technology from '../assets/images/technology.png';
import technology_1 from '../assets/images/technology_1.png';
import work_on from '../assets/images/work_on.png';
import Customers from '../assets/images/Customers.png';
import blogs from '../assets/images/blogs.png';
import negative from '../assets/images/negative.png';
import positive from '../assets/images/positive.png';
import html from "../assets/images/html.svg";
import css from "../assets/images/css.svg";
import jquery from "../assets/images/jquery.svg";
import bootstrap from "../assets/images/bootstrap.svg";
import php from "../assets/images/php.svg";
import ajax from "../assets/images/ajax.svg";
import mysql from "../assets/images/mysql.svg";
import laravel from "../assets/images/laravel.svg";
import dooper from "../assets/images/dooper.png";
import sumitian from "../assets/images/sumitian.png";
import practically from "../assets/images/practically.png";

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
      duration: 0.5,
    },
  }),
};

const serviceVariants = {
  hiddenLeft: { opacity: 0, x: -100 },
  hiddenRight: { opacity: 0, x: 100 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.2,
      duration: 0.8,
    },
  }),
};

const PageWrapper = styled.div`
  font-family: 'Poppins';
  color: #434343;
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Section = styled.section`
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;

const Container = styled.div`
  max-width: 65vw;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px;
    margin-top:50px;
  }
`;

const HeroSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding-top: 20px;
  }
`;

const HeroContent = styled.div`
  flex: 1;
`;

const HeroImage = styled.img`
  flex: 1;
  max-width: 40%;
  @media (max-width: 768px) {
    max-width: 80%;
    margin-top: 20px;
  }
`;

const Title = styled.h2`
  font-size: 46px;
  background: linear-gradient(
    270deg,
    #000000 60%,
    #1a1a1a 70%,
    #0072ff 85%,
    #2f69b1 100%
  );
  background-size: 200% 200%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${gradientAnimation} 3s ease infinite;
  margin-bottom: 20px;
  // text-align: center;
  font-weight: 700;
  line-height: 62.4px;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 1.3;
  text-align: center;

    margin-bottom: 15px;
    padding: 0 20px;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.5;
    padding: 0 20px;
  text-align: center;

  }
`;

const Button = styled.button`
  background-color: #015bcc;
  color: white;
  border: none;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  @media (min-width: 768px) {
    &:hover {
      background: linear-gradient(to right, #0072ff 0%, #2f69b1 100%);
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureItem = styled(motion.div)`
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 25px;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  @media (min-width: 768px) {
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
`;

const FeatureIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
  color: #434343;
  font-weight: 600;
`;

const FeatureDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceItem = styled(motion.div)`
  background-color: #ffffff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  @media (min-width: 768px) {
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
`;

const ServiceTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
  color: #434343;
  font-weight: 600;
`;

const ServiceDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
 
  margin-top: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectItem = styled.div`
  height: 300px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  @media (max-width: 768px) {
    height: 250px;
  }
`;

const ProjectImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ProjectContent = styled.div`
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

const ProjectTitle = styled.h4`
  font-size: 20px;
  margin-bottom: 10px;
  color: #434343;
`;

const ProjectDescription = styled.p`
  font-size: 14px;
  margin-bottom: 15px;
  color: #434343;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.expanded ? "unset" : "3")};
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:not(.expanded)::after {
    content: "... View All";
    position: absolute;
    right: 0;
    bottom: 0;
    color: #015bcc;
    cursor: pointer;
    background: linear-gradient(to right, transparent, #ffffff 20%);
    padding-left: 20px;
  }

  &.expanded::after {
    content: "... Collapse";
    position: absolute;
    right: 0;
    bottom: 0;
    color: #015bcc;
    cursor: pointer;
    background: #ffffff;
    padding-left: 4px;
  }
`;

const ReadMore = styled.a`
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  color: #015bcc;
  text-decoration: none;
  @media (min-width: 768px) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const TechStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 10px;
`;

const TechIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const TestimonialSection = styled(Section)`
  text-align: center;
`;
const ProjectGridWrapper = styled.div`
  margin-top: 25px;
`;

const ProjectHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const ViewAllText = styled.h3`
  font-size: 18px;
  cursor: pointer;
  color: #434343;
  &:hover {
    color: #015bcc;
  }
`;

const TestimonialCard = styled.div`
  display: flex;
  border: 1px solid #6c6c6c;
  border-radius: 60px;
  padding: 40px;
  margin-top: 25px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    border-radius: 30px;
  }
`;

const TestimonialImage = styled.img`
  width: 20%;
  margin-right: 40px;
  @media (max-width: 768px) {
      display: none;

  }
`;

const TestimonialContent = styled.div`
  flex: 1;
`;

const TestimonialText = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const TestimonialAuthor = styled.h4`
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 600;
`;

const TestimonialPosition = styled.p`
  font-size: 14px;
  color: #666;
`;

const FAQSection = styled(Section)``;

const FAQContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FAQContent = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const FAQItem = styled.div`
  margin-bottom: 30px;
  padding-bottom: 20px;
  text-align: left;
`;

const FAQQuestion = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #122740;
  margin-bottom: 10px;
  gap: 15px;
`;

const FAQToggle = styled.img`
  width: 54px;
  height: 54px;
  min-width: 54px;
  transition: transform 0.3s ease;
  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
`;

const FAQAnswer = styled.div`
  margin-top: 10px;
  padding-left: 69px;
  @media (max-width: 768px) {
    padding-left: 34px;
  }
`;

const FAQDescription = styled.p`
  font-size: 14px;
  color: #6b6f82;
  margin-bottom: 10px;
  line-height: 1.6;
`;

const FAQImage = styled.img`
  width: 35%;
  object-fit: contain;
  @media (max-width: 768px) {
    display: none;
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const BottomText = styled.p`
  font-size: 20px;
  color: #434343;
  text-align: left;
  flex: 1;
  line-height: 30px;
  font-weight: 700;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ConsultButton = styled(Button)`
  width: 25%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ViewAllButton = styled(Button)`
  width: 30%;
  margin-top: 40px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const AwardsDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
`;

const AwardsDot = styled.span`
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#434343" : "#6C6C6C")};
  border-radius: 50%;
`;

const Technology = () => {
  const navigate = useNavigate();
  const [openQuestion, setOpenQuestion] = useState(0);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [activeDot, setActiveDot] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe && activeDot < testimonials.length - 1) {
      setActiveDot((prev) => prev + 1);
    }

    if (isRightSwipe && activeDot > 0) {
      setActiveDot((prev) => prev - 1);
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  const toggleDescription = (cardId) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [cardId]: !prev[cardId],
    }));
  };
  const projects = [
    {
      title: "Dooper",
      description: "Dooper embarked on a mission to redefine healthcare services by providing a comprehensive application catering to all hospital needs. The primary objective was to create a user-friendly platform that seamlessly integrates ambulance booking, appointments with health assistants and doctors, prescription management, lab test scheduling, and more. The goal was to enhance the overall efficiency of hospital operations and improve patient care.",
      image: dooper
    },
    {
      title: "Sumitian",
      description: "Sumit EdTech, a prominent coaching institute based in Bihar, embarked on a mission to enhance its educational offerings beyond live classes. The primary business goal was to create a robust Learning Management System (LMS) through a dedicated application. The aim was to enable students from classes nine to twelve to access recorded sessions and study materials post-live classes. Through an intuitive admin portal, instructors could upload content, which students could then access offline and download for future reference. This initiative sought to bridge the gap between live sessions and self-paced learning, providing a holistic educational experience for subscribed students",
      image: sumitian
    }
  ];
  
  const features = [
    { title: 'Vast Libraries', description: 'Extensive collection of pre-built modules and packages for rapid development.' },
    { title: 'Scalability', description: 'Easily scale applications to handle growing user demands and traffic.' },
    { title: 'Lower Costs', description: 'Reduce development costs with efficient resource utilization.' },
    { title: 'Frameworks', description: 'Rich ecosystem of frameworks for different development needs.' },
    { title: 'Real-Time Apps', description: 'Perfect for building real-time applications with seamless data flow.' },
    { title: 'Proxy Server', description: 'Efficient proxy server capabilities for enhanced performance.' }
  ];

  const services = [
    { title: 'Custom Node.Js Development', description: 'Tailored Node.js solutions to meet your specific business requirements.' },
    { title: 'Node.Js Microservices', description: 'Build scalable and maintainable microservices architecture.' },
    { title: 'Node.Js Integration', description: 'Seamlessly integrate Node.js with existing systems and platforms.' },
    { title: 'Node.Js Mobile Apps', description: 'Create powerful mobile applications using Node.js backend.' },
    { title: 'Node.Js API Development', description: 'Design and develop robust APIs for your applications.' },
    { title: 'Node.Js Plugin Development', description: 'Custom plugins to extend functionality and features.' }
  ];



  const testimonials = [
    {
      text: "Jethi Tech Solutions delivered an exceptional Node.js solution that transformed our business operations. Their expertise and professionalism exceeded our expectations.",
      author: "Mark Rise",
      position: "CEO, Founder"
    },
    {
      text: "Working with Jethi Tech Solutions was a game-changer for our company. Their Node.js development team delivered outstanding results.",
      author: "Sarah Johnson",
      position: "CTO"
    },
    {
      text: "Excellent service and technical expertise. The team at Jethi Tech Solutions helped us build a robust and scalable application.",
      author: "John Smith",
      position: "Product Manager"
    }
  ];

  const faqData = [
    {
      question: "Why choose Node.js for your project?",
      answer: "Node.js offers excellent performance, scalability, and a rich ecosystem of packages. It's perfect for real-time applications and microservices architecture."
    },
    {
      question: "What is your development process?",
      answer: "We follow an agile methodology with regular client communication, iterative development, and continuous integration/deployment practices."
    },
    {
      question: "How do you ensure code quality?",
      answer: "We implement strict code review processes, automated testing, and follow industry best practices to maintain high code quality standards."
    }
  ];

  return (
    <PageWrapper>
      <Container>
        <HeroSection data-aos="fade-up">
          <HeroContent>
            <Title>Node.js Development Company & Team Augmentation Services</Title>
            <Subtitle>Transform your business with our expert Node.js development services. We deliver scalable, high-performance solutions tailored to your needs.</Subtitle>
            <Button onClick={() => navigate("/contact")}>Consult Now</Button>
          </HeroContent>
          <HeroImage src={technology} alt="Node.js Development" />
        </HeroSection>

        <Section data-aos="fade-up">
          <Title>Why Choose Node.Js Development?</Title>
          <Subtitle>Leverage the power of Node.js to build fast, scalable, and efficient applications.</Subtitle>
          <FeatureGrid>
            {features.map((feature, index) => (
              <FeatureItem
                key={index}
                custom={index}
                initial="hidden"
                whileInView="visible"
                variants={cardVariants}
                viewport={{ once: true }}
              >
                <FeatureIcon src={technology_1} alt={feature.title} />
                <FeatureContent>
                  <FeatureTitle>{feature.title}</FeatureTitle>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </FeatureContent>
              </FeatureItem>
            ))}
          </FeatureGrid>
        </Section>

        <Section data-aos="fade-up">
          <Title>Our Node.js Development Services</Title>
          <Subtitle>Comprehensive Node.js solutions to power your digital transformation</Subtitle>
          <ServiceGrid>
            {services.map((service, index) => (
              <ServiceItem
                key={index}
                custom={index}
                initial={index % 2 === 0 ? "hiddenLeft" : "hiddenRight"}
                whileInView="visible"
                variants={serviceVariants}
                viewport={{ once: true }}
              >
                <ServiceTitle>{service.title}</ServiceTitle>
                <ServiceDescription>{service.description}</ServiceDescription>
              </ServiceItem>
            ))}
          </ServiceGrid>
          <BottomRow>
            <BottomText>
              Our expertise in designing and building digital solutions
              authorizes us to develop bespoke solutions
            </BottomText>
            <ConsultButton onClick={() => navigate("/contact")}>Consult Now!</ConsultButton>
          </BottomRow>
        </Section>
        <Section data-aos="fade-up">
  <ProjectGridWrapper>
    <ProjectHeadingWrapper>
      <Title>See Our Experience</Title>
      <ViewAllText onClick={() => navigate("/work")}>View All →</ViewAllText>
    </ProjectHeadingWrapper>
    <Subtitle>Discover how we've helped businesses achieve their goals with Node.js</Subtitle>
    <ProjectGrid>
      {projects.map((project, index) => (
        <div style={{border: "1px solid #ccc", borderRadius: "10px",padding: "20px"}} key={index}>
          <ProjectItem>
            <ProjectImage src={project.image} alt={project.title} />
          </ProjectItem>
          <ProjectContent>
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription
              className={expandedDescriptions[index] ? "expanded" : ""}
              expanded={expandedDescriptions[index]}
              onClick={() => toggleDescription(index)}
            >
              {project.description}
            </ProjectDescription>
            <TechStack>
              <TechIcon src={html} alt="html" />
              <TechIcon src={css} alt="css" />
              <TechIcon src={jquery} alt="jquery" />
              <TechIcon src={bootstrap} alt="bootstrap" />
              <TechIcon src={php} alt="php" />
              <TechIcon src={ajax} alt="ajax" />
              <TechIcon src={mysql} alt="mysql" />
              <TechIcon src={laravel} alt="laravel" />
            </TechStack>
            <ReadMore onClick={() => navigate("/work")}>Read More →</ReadMore>
          </ProjectContent>
        </div>
      ))}
    </ProjectGrid>
  </ProjectGridWrapper>
</Section>

        <TestimonialSection data-aos="fade-up">
          <Title>Our Customers love what we do</Title>
          <Subtitle>
            We take pride in delivering exceptional solutions that exceed our clients' expectations.
          </Subtitle>
          <TestimonialCard
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <TestimonialImage src={Customers} alt="Customer Testimonial" />
            <TestimonialContent>
              <TestimonialText>{testimonials[activeDot].text}</TestimonialText>
              <TestimonialAuthor>{testimonials[activeDot].author}</TestimonialAuthor>
              <TestimonialPosition>{testimonials[activeDot].position}</TestimonialPosition>
              <AwardsDots>
                {testimonials.map((_, index) => (
                  <AwardsDot
                    key={index}
                    active={index === activeDot}
                    onClick={() => setActiveDot(index)}
                  />
                ))}
              </AwardsDots>
            </TestimonialContent>
          </TestimonialCard>
        </TestimonialSection>

        <FAQSection data-aos="fade-up">
          <Title>FAQ</Title>
          <FAQContainer>
            <FAQContent>
              {faqData.map((item, index) => (
                <FAQItem key={index}>
                  <FAQQuestion onClick={() => setOpenQuestion(openQuestion === index ? -1 : index)}>
                    <FAQToggle src={openQuestion === index ? negative : positive} alt="Toggle" />
                    {item.question}
                  </FAQQuestion>
                  {openQuestion === index && (
                    <FAQAnswer>
                      <FAQDescription>{item.answer}</FAQDescription>
                    </FAQAnswer>
                  )}
                </FAQItem>
              ))}
              <ViewAllButton onClick={() => navigate("/contact")}>View All</ViewAllButton>
            </FAQContent>
            <FAQImage src={blogs} alt="FAQ Illustration" />
          </FAQContainer>
        </FAQSection>
      </Container>
    </PageWrapper>
  );
};

export default Technology;