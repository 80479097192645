import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import serivce from "../assets/images/serivce.png";
import ui from "../assets/images/ui.png";
import AOS from "aos";
import "aos/dist/aos.css";
import web from "../assets/images/web.png";
import desktop from "../assets/images/desktop.png";
import cross from "../assets/images/cross.png";
import industries_1 from "../assets/images/industries_1.svg";
import industries_2 from "../assets/images/industries_2.svg";
import industries_3 from "../assets/images/industries_3.svg";
import industries_4 from "../assets/images/industries_4.svg";
import industries_5 from "../assets/images/industries_5.svg";
import industries_6 from "../assets/images/industries_6.svg";
import industries_7 from "../assets/images/industries_7.svg";
import industries_8 from "../assets/images/industries_8.svg";
import industries_9 from "../assets/images/industries_9.svg";
import industries_10 from "../assets/images/industries_10.svg";
import developmentProcessImage from "../assets/images/developmentProcessImage.svg";
import Customers from "../assets/images/Customers.png";
import blogs from "../assets/images/blogs.png";
import negative from "../assets/images/negative.png";
import positive from "../assets/images/positive.png";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";

const PageWrapper = styled.div`
  font-family: "Poppins";
  color: #434343;
`;

const Section = styled.section`
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;

const Section0 = styled.section`
  padding: 0px 0;
  @media (max-width: 768px) {
    padding: 0px 0;
  }
`;

const Container = styled.div`
  max-width: 65vw;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px;
    margin-top:50px;
  }
`;

const Button = styled.button`
  background-color: #015bcc;
  color: white;
  border: none;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  @media (min-width: 768px) {
    &:hover {
      background: linear-gradient(to right, #0072ff 0%, #2f69b1 100%);
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const DesignSolutionsSection = styled(Section)``;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Tab = styled.button`
  background-color: ${(props) => (props.active ? "#015BCC" : "white")};
  color: ${(props) => (props.active ? "white" : "#434343")};
  border: 1px solid #015bcc;
  padding: 14px;
  font-family: "Poppins";
  cursor: pointer;
  flex: 1;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  border-radius: 10px;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 16px;
  }

  @media (min-width: 768px) {
    &:hover {
      background: ${(props) => (!props.active && "#f5f5f5")};
    }
  }
`;

const cardVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.1,
      duration: 0.5,
    },
  }),
};

const SolutionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProcessSection = styled(Section)`
  text-align: center;
`;

const ProcessImage = styled.img`
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
`;

const CustomerTestimonial = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #6c6c6c;
  border-radius: 60px;
  padding: 40px;
  margin-top: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    border-radius: 30px;
  }
`;

const TestimonialImage = styled.img`
  width: 20%;
  margin-right: 40px;
  @media (max-width: 768px) {
        display: none;

  }
`;

const TestimonialContent = styled.div`
  flex: 1;
`;

const TestimonialText = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const TestimonialAuthor = styled.h4`
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 600;
`;

const TestimonialPosition = styled.p`
  font-size: 14px;
  color: #666;
`;

const FAQSection = styled(Section0)``;

const FAQContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FAQContent = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const FAQItem = styled.div`
  margin-bottom: 30px;
  padding-bottom: 20px;
  text-align: left;
`;

const FAQQuestion = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #122740;
  margin-bottom: 10px;
  gap: 15px;
`;

const FAQToggle = styled.img`
  width: 54px;
  height: 54px;
  min-width: 54px;
  transition: transform 0.3s ease;
  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
`;

const FAQAnswer = styled.div`
  margin-top: 10px;
  padding-left: 69px;
  @media (max-width: 768px) {
    padding-left: 34px;
  }
`;

const FAQDescription = styled.p`
  font-size: 14px;
  color: #6b6f82;
  margin-bottom: 10px;
  line-height: 1.6;
`;

const FAQImage = styled.img`
  width: 35%;
  object-fit: contain;
  @media (max-width: 768px) {
    display: none;
  }
`;

const HeroSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding-top: 20px;
  }
`;

const HeroImage = styled.img`
  flex: 1;
  max-width: 40%;
  @media (max-width: 768px) {
    max-width: 80%;
    margin-top: 20px;
  }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;
const IndustryGridItem = styled.div`
  text-align: center;
  padding: 10px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 1px solid #6c6c6c;
  }
  @media (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const HeroTitle = styled.h1`
  font-size: 46px;
  background: linear-gradient(
    270deg,
    #000000 60%,
    #1a1a1a 70%,
    #0072ff 85%,
    #2f69b1 100%
  );
  background-size: 200% 200%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${gradientAnimation} 3s ease infinite;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 62.4px;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 1.3;
    margin-bottom: 15px;
    padding: 0 20px;
  }
`;

const HeroContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 46px;
  background: linear-gradient(
    270deg,
    #000000 60%,
    #1a1a1a 70%,
    #0072ff 85%,
    #2f69b1 100%
  );
  background-size: 200% 200%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${gradientAnimation} 3s ease infinite;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  line-height: 62.4px;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 1.3;
    margin-bottom: 15px;
    padding: 0 20px;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.5;
    padding: 0 20px;
  }
`;

const Subtitle2 = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
  }
`;

const ValueSection = styled(Section0)`
  text-align: center;
  background-color: #ffffff;
`;

const StatsGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 15px;
  }
`;

const StatItem = styled(motion.div)`
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
`;

const StatNumber = styled.h3`
  font-size: 36px;
  color: #2f2f2f;
  margin-bottom: 10px;
  border-bottom: 1px solid #5c5c5c;
`;

const StatLabel = styled.p`
  font-size: 20px;
  margin-left: 30px;
  color: #434343;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-left: 0;
  }
`;

const SolutionItem = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  @media (min-width: 768px) {
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
`;

const TechnologiesSection = styled(Section)`
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 20px 0;
    width: 100%;
  }
`;

const AwardsDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
`;

const AwardsDot = styled.span`
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#434343" : "#6C6C6C")};
  border-radius: 50%;
  margin: 0 5px;
`;

const SolutionIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const SolutionContent = styled.div`
  flex: 1;
`;

const SolutionTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
  color: #434343;
  font-weight: 600;
`;

const SolutionDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
`;

const IndustriesSection = styled(Section)``;

const IndustriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin: 40px 0;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 90%;
    margin: 20px auto;
  }
`;

const IndustryGridIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
  }
`;

const IndustryName = styled.p`
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins";
  font-weight: 400;
  color: #434343;
  margin: 0;
  padding: 0 5px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.3;
    padding: 0;
    margin-top: 5px;
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const BottomText = styled.p`
  font-size: 20px;
  color: #434343;
  text-align: left;
  flex: 1;
  line-height: 30px;
  font-weight: 700;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ConsultButton = styled(Button)`
  width: 35%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const ConsultButton2 = styled(Button)`
  width: 20%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ServiceComponent = () => {
  const [activeTab, setActiveTab] = useState("Application Design");
  const [openQuestion, setOpenQuestion] = useState(0);
  const { ref: refValue, inView: inViewValue } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  const [activeDot, setActiveDot] = useState(0);
  
  const testimonials = [
    {
      text: "Jethi Tech Solutions has been a game-changer for our business. Their team delivered a top-notch solution that exceeded our expectations. We're thrilled with the results!",
      author: "Mark Rise",
      position: "CEO, Founder",
    },
    {
      text: "Since implementing Jethi Tech Solutions, we've seen a 25% increase in website traffic and a 15% boost in conversions. The team truly understands our business needs.",
      author: "Jane Doe",
      position: "Marketing Director",
    },
    {
      text: "Working with Jethi Tech Solutions felt like a true partnership. They were responsive, proactive, and always willing to go the extra mile. We highly recommend their services.",
      author: "John Smith",
      position: "CEO, Founder",
    },
  ];

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe && activeDot < testimonials.length - 1) {
      setActiveDot((prev) => prev + 1);
    }

    if (isRightSwipe && activeDot > 0) {
      setActiveDot((prev) => prev - 1);
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  const designSolutions = {
    "Application Design": [
      {
        icon: ui,
        title: "UX/UI Design",
        description: "Creating wireframes to be implemented by the developer, understanding the end-user journey, creating and testing/optimizing the site interface, creating mobile app design and interface.",
      },
      {
        icon: web,
        title: "Web application Design",
        description: "Our UX team and web developers will work together to create an intuitive application, optimized for mobile devices and page speed. We also have SEO specialists who can boost your ranking.",
      },
      {
        icon: desktop,
        title: "Desktop Software Design",
        description: "Full-stack design services ranging from software interface development to database redesign.",
      },
      {
        icon: cross,
        title: "Cross-Platform Design",
        description: "Build once, deploy anywhere! We can create custom UX designs that will look superb on all devices.",
      },
    ],
    "Web Design": [
      {
        icon: ui,
        title: "Responsive Web Design",
        description: "Our web developers can adapt your designs to make them easily accessible on mobile devices and tablets.",
      },
      {
        icon: web,
        title: "E-commerce Solutions",
        description: "We create appealing store designs and can create a captivating SEO-optimized copy for your goods.",
      },
      {
        icon: desktop,
        title: "Brochure Sites",
        description: "If you only need a simpler promotional site with a few static plain pages and user interface design, our web developers can create captivating pages that bring conversions.",
      },
      {
        icon: cross,
        title: "Blog Design",
        description: "Nice Graphics and captivating forms are a must if you want to make the most out of your blogs.",
      },
    ],
    "Graphic Design": [
      {
        icon: ui,
        title: "Logo Design",
        description: "Looking to create a custom logo that communicates your brand identity? We've got you covered!",
      },
      {
        icon: web,
        title: "Print Materials",
        description: "The best design assets are the ones that you can touch. We can design Print Materials of all formats.",
      },
      {
        icon: desktop,
        title: "Illustrative Design",
        description: "We work with many different styles of illustrations, including images for books and comics, emojis and much more.",
      },
    ],
    "Brand Identity": [
      {
        icon: ui,
        title: "Corporate Identity Development",
        description: "When you present your brand assets, they have to be cohesive! We can help you develop an identity package that will communicate your values effectively.",
      },
      {
        icon: web,
        title: "Infographics",
        description: "One picture tells a thousand words. Communicate your workflows and engage the audience with custom infographic creation services.",
      },
      {
        icon: desktop,
        title: "Sales Presentations",
        description: "Communicate your offering to potential clients with eye-catching presentations.",
      },
      {
        icon: cross,
        title: "Brochures",
        description: "Think of your company brochure as the elevator pitch created for each one of your potential clients.",
      },
    ],
  };

  const faqData = [
    {
      question: "What services do you offer in UX/UI design?",
      answer: "We offer comprehensive UX/UI design services including user research, wireframing, prototyping, visual design, interaction design, and usability testing. Our team creates intuitive and engaging digital experiences across web, mobile, and desktop platforms.",
    },
    {
      question: "How do you ensure the quality of your design solutions?",
      answer: "We follow a rigorous design process that includes multiple rounds of testing, user feedback, and quality assurance. Our team stays updated with the latest design trends and best practices to deliver modern, effective solutions.",
    },
    {
      question: "What is your typical project timeline?",
      answer: "Project timelines vary based on scope and complexity. A typical design project can range from 4-12 weeks. We provide detailed project timelines during initial consultations and maintain transparent communication throughout the process.",
    },
  ];
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <Container>
        <HeroSection data-aos="fade-up">
          <HeroContentWrapper>
            <HeroTitle>Unleash Your Company's Creative Vision With Custom UX and UI Design Services</HeroTitle>
            <Subtitle2>Transform your digital presence with our expert design team. We create intuitive, engaging, and conversion-focused user experiences.</Subtitle2>
            <ConsultButton onClick={()=>{navigate("/contact")}}>Consult Now!</ConsultButton>
          </HeroContentWrapper>
          <HeroImage src={serivce} alt="Transform your business" />
        </HeroSection>

        <DesignSolutionsSection data-aos="fade-up">
          <Title>Our Design Solutions</Title>
          <Subtitle>We deliver comprehensive design solutions that combine creativity with functionality to help your business stand out.</Subtitle>
          <TabContainer>
            {Object.keys(designSolutions).map((tab) => (
              <Tab
                key={tab}
                active={activeTab === tab}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </Tab>
            ))}
          </TabContainer>
          <SolutionGrid>
            {designSolutions[activeTab].map((solution, index) => (
              <SolutionItem
                key={index}
                custom={index}
                initial="hidden"
                whileInView="visible"
                variants={cardVariants}
                viewport={{ once: true }}
              >
                <SolutionIcon src={solution.icon} alt={solution.title} />
                <SolutionContent>
                  <SolutionTitle>{solution.title}</SolutionTitle>
                  <SolutionDescription>{solution.description}</SolutionDescription>
                </SolutionContent>
              </SolutionItem>
            ))}
          </SolutionGrid>
        </DesignSolutionsSection>

        <IndustriesSection data-aos="fade-up">
          <Title>Key industries we serve</Title>
          <Subtitle>
            We understand the unique challenges and opportunities facing each industry we serve. Our tailored digital solutions are designed to streamline operations, enhance customer experiences, and drive growth for businesses of all sizes.
          </Subtitle>
          <IndustriesGrid>
            {[
              { icon: industries_1, name: "Banking, Financial Services & Insurance" },
              { icon: industries_2, name: "Media, News & Entertainment" },
              { icon: industries_3, name: "Retail & Consumer Services" },
              { icon: industries_4, name: "Travel, Tourism & Leisure" },
              { icon: industries_5, name: "EdTech & Learning" },
              { icon: industries_6, name: "Real estate & Prop Tech" },
              { icon: industries_7, name: "Pharma & Healthcare" },
              { icon: industries_8, name: "Business & IT Services" },
              { icon: industries_9, name: "Automotive & Manufacturing" },
              { icon: industries_10, name: "Energy & Utilities" },
            ].map((industry, index) => (
              <IndustryGridItem key={index}>
                <IndustryGridIcon src={industry.icon} alt={industry.name} />
                <IndustryName>{industry.name}</IndustryName>
              </IndustryGridItem>
            ))}
          </IndustriesGrid>
          <BottomRow>
            <BottomText>Our expertise in designing and building digital solutions authorizes us to develop bespoke solutions</BottomText>
            <ConsultButton2 onClick={()=>{navigate("/contact")}}>Consult Now!</ConsultButton2>
          </BottomRow>
        </IndustriesSection>

        <ValueSection ref={refValue} data-aos="fade-up">
          <Title>Jethi Tech Solutions Deliver Value for Money</Title>
          <Subtitle>
            We deliver unparalleled value through innovative technology solutions, competitive pricing, and exceptional customer support.
          </Subtitle>
          <StatsGrid>
            {[
              { end: 99, suffix: "%", label: "◾ Satisfied Clients" },
              { end: 160, suffix: "+", label: "◾ Tech Enthusiasts" },
              { end: 30, suffix: "+", label: "◾ Industries covered" },
              { end: 550, suffix: "+", label: "◾ Projects Delivered" },
              { end: 80, suffix: "%+", label: "◾ Impressive growth of Product" },
              { end: 3.4, suffix: "M+", label: "◾ Lines of Code" },
              { end: 20, suffix: "+", label: "◾ Industry Certified Team members" },
              { end: 850, suffix: "+", label: "◾ On time project delivery." },
            ].map((stat, index) => (
              <StatItem
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={inViewValue ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5 }}
              >
                <StatNumber>
                  <CountUp
                    start={inViewValue ? 0 : null}
                    end={stat.end}
                    suffix={stat.suffix}
                    duration={2.5}
                  />
                </StatNumber>
                <StatLabel>{stat.label}</StatLabel>
              </StatItem>
            ))}
          </StatsGrid>
          <BottomRow>
            <BottomText>
              Our expertise in designing and building digital solutions authorizes us to develop bespoke solutions
            </BottomText>
            <ConsultButton2 onClick={()=>{navigate("/contact")}}>Consult Now!</ConsultButton2>
          </BottomRow>
        </ValueSection>

        <ProcessSection data-aos="fade-up">
          <Title>Development Process we Follow</Title>
          <Subtitle>
            We follow an agile development methodology, emphasizing iterative development, continuous improvement, and collaboration among cross-functional teams.
          </Subtitle>
          <ProcessImage src={developmentProcessImage} alt="Development Process" />
        </ProcessSection>

        <TechnologiesSection data-aos="fade-up">
        <Container>
          <Title>Our Customers love what we do</Title>
          <Subtitle>
            Our customers are at the center of everything we do, and we're
            passionate about delivering exceptional solutions that exceed their
            expectations. We take pride in providing innovative services that
            delight and satisfy their needs, fostering long-term relationships
            built on trust, quality, and reliability. Our dedication to customer
            satisfaction is unwavering, and we continually strive to improve and
            refine our offerings to meet their evolving needs.
          </Subtitle>
          <CustomerTestimonial
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <TestimonialImage src={Customers} alt="Customer Testimonial" />
            <TestimonialContent>
              <TestimonialText>{testimonials[activeDot].text}</TestimonialText>
              <TestimonialAuthor>
                {testimonials[activeDot].author}
              </TestimonialAuthor>
              <TestimonialPosition>
                {testimonials[activeDot].position}
              </TestimonialPosition>
              <AwardsDots>
                {testimonials.map((_, index) => (
                  <AwardsDot
                    key={index}
                    active={index === activeDot}
                    onClick={() => setActiveDot(index)}
                  />
                ))}
              </AwardsDots>
            </TestimonialContent>
          </CustomerTestimonial>
        </Container>
      </TechnologiesSection>

        <FAQSection data-aos="fade-up">
          <Title>Frequently Asked Questions</Title>
          <FAQContainer>
            <FAQContent>
              {faqData.map((item, index) => (
                <FAQItem key={index}>
                  <FAQQuestion onClick={() => setOpenQuestion(openQuestion === index ? -1 : index)}>
                    <FAQToggle src={openQuestion === index ? negative : positive} alt="Toggle" />
                    {item.question}
                  </FAQQuestion>
                  {openQuestion === index && (
                    <FAQAnswer>
                      <FAQDescription>{item.answer}</FAQDescription>
                    </FAQAnswer>
                  )}
                </FAQItem>
              ))}
            </FAQContent>
            <FAQImage src={blogs} alt="FAQ Illustration" />
          </FAQContainer>
        </FAQSection>
      </Container>
    </PageWrapper>
  );
};

export default ServiceComponent;