import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Slider from "react-slick";
import AOS from "aos";
import { Link as ScrollLink } from "react-scroll";
import { X } from "lucide-react";
import Customers from "../assets/images/Customers.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import about from "../assets/images/about.png";
import award_1 from "../assets/images/award_1.svg";
import award_2 from "../assets/images/award_2.svg";
import award_3 from "../assets/images/award_3.svg";
import award_4 from "../assets/images/award_4.svg";
import award_5 from "../assets/images/award_5.svg";
import award_6 from "../assets/images/award_6.svg";
import trusted_partner_1 from "../assets/images/trusted_partner_1.svg";
import trusted_partner_2 from "../assets/images/trusted_partner_2.svg";
import trusted_partner_4 from "../assets/images/trusted_partner_4.svg";
import trusted_partner_5 from "../assets/images/trusted_partner_5.svg";
import trusted_partner_6 from "../assets/images/trusted_partner_6.svg";
import trusted_partner_7 from "../assets/images/trusted_partner_7.svg";
import trusted_partner_8 from "../assets/images/trusted_partner_8.svg";

const AboutWrapper = styled.div`
  font-family: "Poppins";
`;

const Section = styled.section`
  padding: 20px 0;
  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const Container = styled.div`
  max-width: 65vw;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px;
  }
`;

const HeroSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    img {
      max-width: 80% !important;
      margin-bottom: 20px;
      margin-top: 40px;
    }
  }
`;

const HeroContent = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HeroTitle = styled.h1`
  font-size: 48px;
  color: #434343;
  margin-bottom: 20px;
  line-height: 62.4px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 1.3;
    margin-bottom: 15px;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
`;

const Button = styled.button`
  background-color: #015bcc;
  color: white;
  border: none;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Poppins";
  &:hover {
    background: linear-gradient(to right, #0072ff 0%, #2f69b1 100%);
  }
  @media (max-width: 768px) {
    padding: 15px 25px;
    font-size: 16px;
    width: 100%;
  }
`;

const Title = styled.h2`
  font-size: 46px;
  background: linear-gradient(
    270deg,
    #000000 60%,
    #1a1a1a 70%,
    #0072ff 85%,
    #2f69b1 100%
  );
  background-size: 200% 200%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${keyframes`
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  `} 3s ease infinite;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  line-height: 62.4px;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 40.4px;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const FormContainer = styled.div`
  flex: 1.2;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const Input = styled.input`
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 92%;
  font-family: "Poppins";
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

const Select = styled.select`
  padding: 12px;
  font-size: 16px;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  font-family: "Poppins";
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

const TextArea = styled.textarea`
  grid-column: span 2;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 95%;
  font-family: "Poppins";
  @media (max-width: 768px) {
    grid-column: span 1;
    font-size: 14px;
    padding: 10px;
  }
`;

const Captcha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 2;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    grid-column: span 1;
    flex-direction: row;
    gap: 10px;
  }
`;

const CaptchaInput = styled.input`
  padding: 12px;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
    width: 80px;
  }
`;

const CaptchaOperator = styled.span`
  font-size: 16px;
  margin: 0 10px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin: 0 5px;
  }
`;

const InfoBox = styled.div`
  flex: 0.8;
  background: linear-gradient(0deg, #2f69b1, #2f69b1),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

  padding: 40px;
  border-radius: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div:first-child {
    font-family: Caveat;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }

  > div:nth-child(2),
  > div:nth-child(3) {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  > div:nth-child(n + 4) {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    gap: 12px;
    align-items: baseline;

    &::before {
      content: attr(data-number);
      font-family: "Figma Hand", cursive;
      font-size: 34px;
      font-weight: 400;
      line-height: 34px;
      min-width: 20px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    padding: 30px;
    gap: 15px;

    > div:first-child {
      font-size: 36px;
      line-height: 36px;
    }

    > div:nth-child(2),
    > div:nth-child(3) {
      font-size: 16px;
      line-height: 20px;
    }

    > div:nth-child(n + 4) {
      font-size: 14px;
      line-height: 20px;

      &::before {
        font-size: 28px;
        line-height: 28px;
      }
    }
  }
`;

const SubmitButton = styled(Button)`
  grid-column: span 2;
  width: 30%;
  margin: 0 auto;
  @media (max-width: 768px) {
    grid-column: span 1;
    width: 100%;
    padding: 15px 20px;
    font-size: 16px;
  }
`;

const AwardsSection = styled(Section)`
  text-align: center;
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;

const TrustedSection = styled(Section)`
  text-align: center;
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;

const TechnologiesSection = styled(Section)`
  padding: 0;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const AwardImage = styled.img`
  height: 100px;
  width: auto;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    height: 80px;
  }
`;

const TrustedLogo = styled.img`
  height: 100px;
  cursor: pointer;
  width: auto;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    height: 80px;
  }
`;

const CustomerTestimonial = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #6c6c6c;
  border-radius: 60px;
  padding: 40px;
  margin-top: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    border-radius: 30px;
  }
`;

const TestimonialImage = styled.img`
  width: 20%;
  margin-right: 40px;
  @media (max-width: 768px) {
    width: 50%;
    margin-right: 0;
    margin-bottom: 20px;
    display: none;
  }
`;

const TestimonialContent = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const TestimonialText = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TestimonialAuthor = styled.h4`
  font-size: 18px;
  margin-bottom: 5px;
  color: #434343;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const TestimonialPosition = styled.p`
  font-size: 14px;
  color: #666;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const AwardsDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
`;

const AwardsDot = styled.span`
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#434343" : "#6C6C6C")};
  border-radius: 50%;
  transition: background-color 0.3s ease;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  &:hover {
    opacity: 0.7;
  }
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
const ModalContent = styled.div`
  background: white;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 700px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 20px;
    width: 95%;
  }
`;

const SuccessModal = styled(ModalOverlay)``;

const SuccessContent = styled(ModalContent)`
  text-align: center;
`;

const FormSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #ffffff;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`;

const ModalForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
    padding: 15px;
  }
`;

const ModalInput = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: "Poppins";

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const ModalSelect = styled.select`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: "Poppins";

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const ModalSubmitButton = styled.button`
  background-color: #015bcc;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Poppins";
  display: block;
  margin: 10px auto 0;

  &:hover {
    background: linear-gradient(to right, #0072ff 0%, #2f69b1 100%);
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 10px 25px;
    font-size: 14px;
    width: auto;
  }
`;

const Contact = () => {
  const [activeDot, setActiveDot] = useState(0);
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  useEffect(() => {
    // Scroll to contact form on component mount
    const element = document.getElementById("contactForm");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);
  const testimonials = [
    {
      text: "Jethi Tech Solutions has been a game-changer for our business. Their team delivered a top-notch solution that exceeded our expectations. We're thrilled with the results!",
      author: "Mark Rise",
      position: "CEO, Founder",
    },
    {
      text: "Since implementing Jethi Tech Solutions, we've seen a 25% increase in website traffic and a 15% boost in conversions. The team at Jethi Tech Solutions truly understands our business needs.",
      author: "Jane Doe",
      position: "Marketing Director",
    },
    {
      text: "Working with Jethi Tech Solutions felt like a true partnership. They were responsive, proactive, and always willing to go the extra mile. We highly recommend their services.",
      author: "John Smith",
      position: "CEO, Founder",
    },
  ];

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    customPaging: (i) => (
      <div
        style={{
          width: "13px",
          height: "13px",
          backgroundColor: i === 0 ? "#333" : "#ccc",
          borderRadius: "50%",
          margin: "0 8px",
          opacity: 1,
        }}
      />
    ),
    dotsClass: "slick-dots slick-thumb",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptchaValue(`${num1} + ${num2}`);
    setCaptchaAnswer((num1 + num2).toString());
  };

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      budget: e.target.budget.value,
      interest: e.target.interest.value,
      source: e.target.source.value,
      type: "quote",
    };

    try {
      await fetch(
        "https://script.google.com/macros/s/AKfycbyJArJogatRAhXwPtRE8J5PnXovdBG_c4M5VgBS8l3euz1dwHjOXum1GI4a6TndzpGK/exec",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      setShowSuccess(true);
      setShowQuoteModal(false);
      // setTimeout(() => {
      //   setShowSuccess(false);
      // }, 2000);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (e.target.captcha.value !== captchaAnswer) {
      toast.error("Incorrect captcha. Please try again.");
      generateCaptcha();
      setIsSubmitting(false);
      return;
    }

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      budget: e.target.budget.value,
      interest: e.target.interest.value,
      source: e.target.source.value,
      requirements: e.target.requirements.value,
      type: "contact",
    };

    try {
      await fetch(
        "https://script.google.com/macros/s/AKfycbyJArJogatRAhXwPtRE8J5PnXovdBG_c4M5VgBS8l3euz1dwHjOXum1GI4a6TndzpGK/exec",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      setShowSuccess(true);
      toast.success("Form submitted successfully!");
      e.target.reset();
      generateCaptcha();
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AboutWrapper>
      <Container>
        <HeroSection>
          <HeroContent>
            <HeroTitle>
              Partner With us to revolutionize Your industry
            </HeroTitle>
            <HeroSubtitle>
              Jethi Tech Solutions creates inspirational designs and robust
              solutions for Web and mobile apps. We combine our expertise in
              tech & design to craft innovative digital solutions that lead the
              way in your industry.
            </HeroSubtitle>
            <ScrollLink to="contactForm" smooth={true} duration={500}>
              <Button>Consult Now</Button>
              {/* <Button onClick={() => setShowQuoteModal(true)}>Consult Now</Button> */}
            </ScrollLink>
          </HeroContent>
          <img src={about} alt="Partner with us" style={{ maxWidth: "40%" }} />
        </HeroSection>

        <Section id="contactForm">
          <Title>Let's work together to turn your vision into a reality</Title>
          <Subtitle>
            Fill this form to Take the First Step Towards the Successful
            Journey.
          </Subtitle>
          <FormSection>
            <FormContainer>
              <Form onSubmit={handleSubmit}>
                <Input type="text" name="name" placeholder="Name*" required />
                <Input
                  type="email"
                  name="email"
                  placeholder="Email*"
                  required
                />
                <Input
                  type="tel"
                  name="phone"
                  placeholder="Contact Number*"
                  required
                />
                <Select name="budget" defaultValue="" required>
                  <option value="" disabled>
                    Your Budget
                  </option>
                  <option value="0-5000">$0 - $5,000</option>
                  <option value="5000-10000">$5,000 - $10,000</option>
                  <option value="10000+">$10,000+</option>
                </Select>
                <Select name="interest" defaultValue="" required>
                  <option value="" disabled>
                    Looking For
                  </option>
                  <option value="Team Extension">Team Extension</option>
                <option value="Product Engineering">Product Engineering</option>
                <option value="Web Development">Web Development</option>
                <option value="Mobility Solutions">Mobility Solutions</option>
                <option value="Emerging Tech">Emerging Tech</option>
                <option value="E-Commerce">E-Commerce</option>
                <option value="Cyber Security">Cyber Security</option>
                <option value="Cloud And Devops">Cloud And Devops</option>
                <option value="Quality Analyst">Quality Analyst</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="PR & Media">PR & Media</option>
                </Select>
                <Select name="source" defaultValue="" required>
                  <option value="" disabled>
                    How did you learn about us
                  </option>
                  <option value="search">Search Engine</option>
                  <option value="social-media">Social Media</option>
                  <option value="referral">Referral</option>
                  <option value="other">Other</option>
                </Select>
                <TextArea
                  name="requirements"
                  placeholder="Project Requirements"
                  rows={4}
                  required
                />
                <Captcha>
                  <CaptchaInput type="text" value={captchaValue} disabled />
                  <CaptchaOperator>=</CaptchaOperator>
                  <CaptchaInput
                    type="text"
                    name="captcha"
                    placeholder="Enter result"
                    required
                  />
                </Captcha>
                <SubmitButton type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Sending..." : "Send"}
                </SubmitButton>
              </Form>
            </FormContainer>
            <InfoBox>
              <div>You have an Idea</div>
              <div>
                We have the experience to transform your idea in Digital Reality
              </div>
              <div>
                Here's what you will get for submitting your contact details
              </div>
              <div data-number="1.">45 minutes of free consultation</div>
              <div data-number="2.">A strict non-disclosure agreement</div>
              <div data-number="3.">Free Market & Competitive Analysis</div>
              <div data-number="4.">
                Suggestions on Revenue Models & Go to Market Strategy
              </div>
              <div data-number="5.">
                No obligation Technical specs & Proposal
              </div>
              <div data-number="6.">
                Guidance on Pre-launch & Post-launch marketing
              </div>
            </InfoBox>
          </FormSection>
        </Section>

        <AwardsSection data-aos="fade-up">
          <Title>Awards and Recognition</Title>
          <Subtitle>
            "We're proud to be recognized as a top performer by Clutch and
            Appfutura, acknowledging our expertise in IT services, software
            development, and iOS app creation."
          </Subtitle>
          <Slider {...carouselSettings}>
            <AwardImage src={award_1} alt="Clutch Award" />
            <AwardImage src={award_2} alt="Top Software Development Company" />
            <AwardImage src={award_3} alt="Top App Developers" />
            <AwardImage src={award_4} alt="Top Mobile App Developers" />
            <AwardImage src={award_5} alt="Top Rated App Development Company" />
            <AwardImage src={award_6} alt="Top Rated App Development" />
          </Slider>
        </AwardsSection>

        <TrustedSection data-aos="fade-up">
          <Title>Trusted By 100+ Happy Brands</Title>
          <Subtitle>
            At Jethi Tech Solutions, we are dedicated to driving continuous
            innovation and digital transformation for our stakeholders. We help
            our clients stay aligned with the latest technology trends, giving
            them a competitive advantage. We are proud to have maintained a 90%
            client retention rate since 2017.
          </Subtitle>
          <Slider {...carouselSettings}>
            <TrustedLogo
              onClick={() => window.open("https://www.powergrid.in/", "_blank")}
              src={trusted_partner_1}
              alt="Trusted Partner 1"
            />
            <TrustedLogo
              onClick={() =>
                window.open("https://www.rainbowhospitals.in/", "_blank")
              }
              src={trusted_partner_2}
              alt="Trusted Partner 2"
            />
            <TrustedLogo
              onClick={() =>
                window.open("https://apwrims.ap.gov.in/", "_blank")
              }
              src={trusted_partner_4}
              alt="Trusted Partner 4"
            />
            <TrustedLogo
              onClick={() => window.open("https://bambinoagro.com/", "_blank")}
              src={trusted_partner_5}
              alt="Trusted Partner 5"
            />
            <TrustedLogo
              onClick={() =>
                window.open("https://suvarnabhoomiinfra.com/", "_blank")
              }
              src={trusted_partner_6}
              alt="Trusted Partner 6"
            />
            <TrustedLogo
              onClick={() =>
                window.open("https://www.merinolaminates.com/en/", "_blank")
              }
              src={trusted_partner_7}
              alt="Trusted Partner 7"
            />
            <TrustedLogo
              onClick={() =>
                window.open("https://www.bachpanglobal.com/", "_blank")
              }
              src={trusted_partner_8}
              alt="Trusted Partner 8"
            />
          </Slider>
        </TrustedSection>

        <TechnologiesSection>
          <Title>Our Customers love what we do</Title>
          <Subtitle>
            Our customers are at the center of everything we do, and we're
            passionate about delivering exceptional solutions that exceed their
            expectations. We take pride in providing innovative services that
            delight and satisfy their needs, fostering long-term relationships
            built on trust, quality, and reliability.
          </Subtitle>
          <CustomerTestimonial>
            <TestimonialImage src={Customers} alt="Customer Testimonial" />
            <TestimonialContent>
              <TestimonialText>{testimonials[activeDot].text}</TestimonialText>
              <TestimonialAuthor>
                {testimonials[activeDot].author}
              </TestimonialAuthor>
              <TestimonialPosition>
                {testimonials[activeDot].position}
              </TestimonialPosition>
              <AwardsDots>
                {testimonials.map((_, index) => (
                  <AwardsDot
                    key={index}
                    active={index === activeDot}
                    onClick={() => setActiveDot(index)}
                  />
                ))}
              </AwardsDots>
            </TestimonialContent>
          </CustomerTestimonial>
        </TechnologiesSection>
      </Container>

      {showQuoteModal && (
        <ModalOverlay onClick={() => setShowQuoteModal(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalCloseButton onClick={() => setShowQuoteModal(false)}>
              <X />
            </ModalCloseButton>
            <ModalTitle>
              Add your information, and we will contact you.
            </ModalTitle>
            <ModalForm onSubmit={handleModalSubmit}>
              <ModalInput
                type="text"
                name="name"
                placeholder="Name*"
                required
              />
              <ModalInput
                type="email"
                name="email"
                placeholder="Email*"
                required
              />
              <ModalInput
                type="tel"
                name="phone"
                placeholder="Contact Number*"
                required
              />
              <ModalSelect name="budget" required>
                <option value="">Your Budget</option>
                <option value="0-5000">$0 - $5,000</option>
                <option value="5000-10000">$5,000 - $10,000</option>
                <option value="10000+">$10,000+</option>
              </ModalSelect>
              <ModalSelect name="interest" required>
                <option value="">Looking For</option>
                <option value="Team Extension">Team Extension</option>
                <option value="Product Engineering">Product Engineering</option>
                <option value="Web Development">Web Development</option>
                <option value="Mobility Solutions">Mobility Solutions</option>
                <option value="Emerging Tech">Emerging Tech</option>
                <option value="E-Commerce">E-Commerce</option>
                <option value="Cyber Security">Cyber Security</option>
                <option value="Cloud And Devops">Cloud And Devops</option>
                <option value="Quality Analyst">Quality Analyst</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="PR & Media">PR & Media</option>
              </ModalSelect>
              <ModalSelect name="source" required>
                <option value="">How did you learn about us</option>
                <option value="search">Search Engine</option>
                <option value="social-media">Social Media</option>
                <option value="referral">Referral</option>
                <option value="other">Other</option>
              </ModalSelect>
              <ModalSubmitButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </ModalSubmitButton>
            </ModalForm>
          </ModalContent>
        </ModalOverlay>
      )}

      {showSuccess && (
        <SuccessModal>
          <SuccessContent>
            <div style={{ textAlign: "center" }}>
              <svg width="44" height="44" viewBox="0 0 64 64" fill="none">
                <circle cx="32" cy="32" r="32" fill="#4CAF50" />
                <path d="M20 32L28 40L44 24" stroke="white" strokeWidth="4" />
              </svg>
              <ModalTitle>Details Submitted successfully</ModalTitle>
              <ModalSubmitButton onClick={() => setShowSuccess(false)}>
                Done
              </ModalSubmitButton>
            </div>
          </SuccessContent>
        </SuccessModal>
      )}

      <ToastContainer position="bottom-right" />
    </AboutWrapper>
  );
};

export default Contact;
