import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import menuIcon from "../assets/images/menu-btn-3-lines.png";
import downArrow from "../assets/images/down-arrow.png";
import servicesImage from "../assets/images/serviceCard.png";
import technologyImage from "../assets/images/technologyCard.png";
import hireImage from "../assets/images/HireSolutionsCard.png";
import solutionsImage from "../assets/images/HireSolutionsCard.png";
import cardBg from "../assets/images/cardBg.png";
import { Menu, X } from "lucide-react";
import logo from "../assets/icons/logo.svg";
import MenuCard from "../pages/MenuCard";
import { toast } from "react-toastify";

// Base DropdownMenu styled component
const DropdownMenu = styled.ul`
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #f5f5f5;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 0px;
  z-index: 1000;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  width: 1100px;
  min-height: 400px;
  color: #434343;
  transition: all 0.3s ease;

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    height: 15px;
  }

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    opacity: 1;
    visibility: visible;
    min-height: auto;
  }
`;

// DropdownMenuShifted component with positioning fixes
const DropdownMenuShifted = styled(DropdownMenu)`
  @media (min-width: 769px) {
    transform: translateX(-65%); // Default shift for most items
    right: auto;
    left: 50%;
    
    // Ensure dropdown is fully visible
    &:before {
      content: '';
      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      height: 15px;
    }
  }

  @media (max-width: 768px) {
    transform: none;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
  }
`;

// Complete NavItem component with all styling and behavior
const NavItem = styled.li`
  margin-right: 30px;
  font-weight: 500;
  font-size: 16px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  // Special positioning for last few items to prevent right-side cutoff
  &:nth-last-child(-n+3) {
    position: relative;
    
    ${DropdownMenuShifted} {
      @media (min-width: 769px) {
        transform: translateX(-75%); // More shift for last items
      }
    }
  }

  // Additional shift for the last item
  &:last-child ${DropdownMenuShifted} {
    @media (min-width: 769px) {
      transform: translateX(-85%); // Maximum shift for last item
    }
  }

  @media (min-width: 769px) {
    &:hover {
      & > ul {
        display: block;
        opacity: 1;
        visibility: visible;
      }

      ${DropdownMenu}, ${DropdownMenuShifted} {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 10px 0;
    
    > ul {
      display: ${(props) => (props.isDropdownOpen ? "block" : "none")};
    }

    ${DropdownMenu}, ${DropdownMenuShifted} {
      width: 100%;
      position: relative;
      transform: none;
      top: 0;
      left: 0;
      margin-top: 10px;
    }
  }

  // Hover state styling
  &:hover > a {
    color: #015bcc;
  }

  // Active state styling
  &.active > a {
    color: #015bcc;
  }

  &.solutions {
    cursor: not-allowed;
    
    a {
      pointer-events: none;
      position: relative;
      
      // &:after {
      //   position: absolute;
      //   bottom: -20px;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   font-size: 12px;
      //   white-space: nowrap;
      //   color: #666;
      // }
    }
  }
`;


const HeaderWrapper = styled.header`
  font-family: "Poppins";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ffffff;
`;

const MainNav = styled.nav`
  background-color: #ffffff;
  padding: 5px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.h1`
  cursor: pointer;
  font-family: "Sofia", cursive;
  font-size: 32px;
  color: #015bcc;
  margin: 0;
  margin-top: 10px;

  img {
    height: 40px;
    width: auto;
  }

  @media (max-width: 768px) {
    font-size: 28px;

    img {
      height: 35px;
      margin-top: 10px;
      width: auto;
    }
  }
`;

const NavMenu = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    display: none; // Hide the regular nav menu on mobile
  }
`;



const NavLink = styled(Link)`
  color: #434343;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  transition: color 0.3s;
  display: flex;
  align-items: center;

  &.active {
    color: #015bcc;
  }

  &:hover {
    color: #015bcc;
  }

  /* Update this part to show arrows for specific nav items */
  &:after {
    content: "";
    display: ${(props) => (props.$hasDropdown ? "inline-block" : "none")}; // Change props.onClick to props.$hasDropdown
    width: 16px;
    height: 16px;
    background-image: url(${downArrow});
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 5px;
    position: relative;
    top: 1px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    @media (max-width: 768px) {
  &:after {
    display: none;
  }
}
  }
`;

const NavLink3 = styled(Link)`
  color: #434343;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  transition: color 0.3s;
  display: flex;
  align-items: center;

  &.active {
    color: #015bcc;
  }

  &:hover {
    color: #015bcc;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;




const CardImage = styled.div`
  flex: 1;
  min-width: 30%;
  background-size: cover;
  background-position: center;
  margin: 20px;
  margin-top: 30px;
  margin-left: 50px;
  margin-bottom: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid #bdbdbd;

  @media (max-width: 768px) {
    margin: 10px;
    margin-top: 20px;
    min-width: 100%;
    height: 200px;
  }
`;

const CardContent = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;



const ServicesContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1px;
  height: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: none;
  }
`;

const TechnologyContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1px;
  height: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: none;
  }
`;

const HireContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  height: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SolutionsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
  height: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.div`
  padding: 10px 20px;
  border-bottom: 1.3px solid #606060;
  border-right: 1.3px solid #606060;

  &:nth-child(3n) {
    border-right: none;
  }

  &:nth-last-child(-n + 2) {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    padding: 10px;
    border-right: none;
    border-bottom: 1.3px solid #606060;

    &:nth-child(3n) {
      border-right: none;
    }

    &:nth-last-child(-n + 2) {
      border-bottom: 1.3px solid #606060;
    }
  }
`;

const GridItemh = styled.div`
  padding: 30px 20px;
  border-bottom: 1.3px solid #606060;
  border-left: 1.3px solid #606060;
  margin-bottom: 100px;
  margin-top: 30px;

  &:nth-child(3n) {
    border-right: none;
  }

  &:nth-last-child(-n + 3) {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    padding: 20px;
    border-left: none;
    margin-bottom: 20px;
    margin-top: 10px;

    &:nth-child(3n) {
      border-right: none;
    }

    &:nth-last-child(-n + 3) {
      border-bottom: 1.3px solid #606060;
    }
  }
`;

const GridItems = styled.div`
  padding: 0px 10px;
  border-bottom: 1.3px solid #606060;
  border-right: 1.3px solid #606060;

  &:nth-child(4n) {
    border-right: none;
  }

  &:nth-last-child(-n + 4) {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    padding: 10px;
    border-right: none;
    border-bottom: 1.3px solid #606060;

    &:nth-child(4n) {
      border-right: none;
    }

    &:nth-last-child(-n + 4) {
      border-bottom: 1.3px solid #606060;
    }
  }
`;

const ColumnTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;

  &:hover {
    color: #2f69b1;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ColumnItem = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  color: #434343;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;



const MenuButton2 = styled.button`
  display: none;
  background: none;
  border: 1px solid #0010f1;
  border-radius: 4px;
  color: #434343;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  margin-right: 10px;

  @media (max-width: 768px) {
    display: flex;
    position: relative;
    z-index: 1001;
  }
`;


const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;

const MenuButton = styled.button`
  background: none;
  border: 1px solid #0010f1;
  color: #434343;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
    position: relative;
    z-index: 1001;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background: white;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 700px;
  z-index: 1002;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;

  &:hover {
    opacity: 0.7;
  }
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

const ModalInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: "Poppins";
`;

const ModalSelect = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: "Poppins";
`;

const ModalSubmitButton = styled.button`
  background-color: #015bcc;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Poppins";
  display: block;
  margin: 10px auto 0;

  &:hover {
    background: linear-gradient(to right, #0072ff 0%, #2f69b1 100%);
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 10px 25px;
    font-size: 14px;
    width: auto;
  }
`;
const SuccessModal = styled(ModalOverlay)``;

const SuccessContent = styled(ModalContent)`
  text-align: center;
`;

const ModalForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
    padding: 15px;
  }
`;

const Card = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 15px;
  background-image: url(${cardBg});
  background-size: cover;
  background-position: right right;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  min-height: 400px;

  @media (max-width: 768px) {
    flex-direction: column;
    min-height: auto;
  }
`;

// Update MenuDropdown for consistency with other dropdowns
const MenuDropdown = styled.div`
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: 0; // Align to right instead of left
  background-color: #f5f5f5;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 0px;
  z-index: 1000;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  width: 350px; // Fixed width for menu dropdown
  min-height: 300px;
  color: #434343;
  transition: all 0.3s ease;

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    height: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
    position: relative;
  }
`;

// Update MenuContainer to fix hover behavior
const MenuContainer = styled.div`
  position: relative;

  @media (min-width: 769px) {
    &:hover {
      ${MenuDropdown} {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;





// Modify NavContent to handle mobile menu
const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;

  @media (max-width: 768px) {
    padding: 0px 20px;
  }
`;


// Update MobileMenuButton styling
const MenuToggle = styled.button`
  display: none;
  background: none;
  border: 1px solid #0010f1;
  border-radius: 4px;
  padding: 5px;
  color: #0010f1;
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// Mobile menu overlay
const MobileMenuOverlay = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: ${props => props.$isOpen ? 'block' : 'none'};
    position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
    height: calc(100vh - 55px);
    background-color: white;
    overflow-y: auto;
    z-index: 1000;
  }
`;
const Header = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const menuRef = useRef();
  const modalRef = useRef();
  const handleMobileNavigation = (path) => {
    // First close the menu
    setIsOpen(false);
    
    // Use a small timeout to ensure menu closes smoothly
    setTimeout(() => {
      // Use window.location for a hard redirect
      window.location.href = path;
    }, 100);
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleQuoteClick = () => {
    setShowQuoteModal(true);
    setIsOpen(false);
  };

  const handleMobileMenuClick = (path, e) => {
    e.preventDefault(); // Prevent default behavior
    setIsOpen(false); // Close the menu
    navigate(path); // Navigate to the path
  };
  

  const handleProfileClick = () => {
    setShowProfileModal(true);
    setIsOpen(false);
  };


  const handleMobileSelectChange = (e) => {
    const path = e.target.value;
    navigate(path);
  };


  const handleSubmit = async (e, formType) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    if(!e.target.name.value || !e.target.email.value || !e.target.phone.value || !e.target.budget.value || !e.target.interest.value || !e.target.source.value) {
      toast.error("Please fill out all fields.");
      setIsSubmitting(false);
      return;
    }
  
    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      budget: e.target.budget.value,
      interest: e.target.interest.value,
      source: e.target.source.value,
      type: formType,
    };
  
    try {
      await fetch(
        "https://script.google.com/macros/s/AKfycbyJArJogatRAhXwPtRE8J5PnXovdBG_c4M5VgBS8l3euz1dwHjOXum1GI4a6TndzpGK/exec",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
  
      setShowSuccess(true);
      if (formType === "quote") {
        setShowQuoteModal(false);
      }
      if (formType === "profile") {
        setShowProfileModal(false);
        
        // Simpler direct download approach
        const link = document.createElement('a');
        link.href = '/pitch_deck_2024.pdf'; // Direct path to PDF in public folder
        link.target = '_blank'; // Open in new tab
        link.download = 'JethiTech_Corporate_Profile.pdf'; // Suggested name for downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        toast.success("Profile download initiated!");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
    setActiveDropdown(null);
  };
  
  const handleMenuClick = (path) => {
    setIsOpen(false);
    setActiveDropdown(null);
    setTimeout(() => {
      navigate(path);
    }, 100);
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setActiveDropdown(null);
    if (showQuoteModal) setShowQuoteModal(false);
    if (showProfileModal) setShowProfileModal(false);
    if (showSuccess) setShowSuccess(false);
  };
  const handleColumnTitleClick = (e) => {
    e.preventDefault();
    // Close any open dropdowns
    setIsOpen(false);
    setActiveDropdown(null);
    // Navigate to contact page
    navigate('/contact');
  };

  return (
    <HeaderWrapper>
      <MainNav>
        <NavContent>
          <Logo onClick={(e) => handleMenuClick("/",e)}>
            <img src={logo} alt="Logo" />
          </Logo>
          <MenuToggle onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </MenuToggle>

          {/* Mobile Menu Overlay */}
          <MobileMenuOverlay $isOpen={isOpen}>
  <MenuCard 
    onQuoteClick={() => {
      setShowQuoteModal(true);
      setIsOpen(false);
    }}
    onProfileClick={() => {
      setShowProfileModal(true);
      setIsOpen(false);
    }}
    onNavigate={(path) => {
      navigate(path);
      setIsOpen(false);
    }}
    isMobile={true}
    onClose={() => setIsOpen(false)}
  />
</MobileMenuOverlay>
          <NavMenu ref={menuRef} $isOpen={isOpen}>
          <NavItem
              isDropdownOpen={activeDropdown === "services"}
              onClick={() =>
                setActiveDropdown(
                  activeDropdown === "services" ? null : "services"
                )
              }
            >
              <NavLink
                to='/services'
                $hasDropdown={true} 
                className={location.pathname === "/services" ? "active" : ""}
              > Services
              </NavLink>
              <DropdownMenu>
                <Card>
                  <CardImage
                    style={{ backgroundImage: `url(${servicesImage})` }}
                  />
                  <CardContent>
                    <ServicesContent>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Team Extension</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Product Engineering</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Web Development</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Mobility Solutions</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Emerging Tech</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                        <ColumnItem>
                          Blockchain | AI/ML | Chatbot App | IoT | Data
                          Analytics
                        </ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>E-commerce</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                        <ColumnItem>
                          Magento | Shopify | Opencart | Prestashop |
                          WooCommerce | Drupal
                        </ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Cyber Security</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                        <ColumnItem>Penetration Testing</ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Cloud And DevOps</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Quality Analyst</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>UI/UX Design</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Digital Marketing</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                      </GridItems>
                      <GridItems>
                         <ColumnTitle  onClick={handleColumnTitleClick}>PR & Media</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                      </GridItems>
                    </ServicesContent>
                  </CardContent>
                </Card>
              </DropdownMenu>
            </NavItem>
            <NavItem
              isDropdownOpen={activeDropdown === "technology"}
             
              onClick={() =>
                setActiveDropdown(
                  activeDropdown === "technology" ? null : "technology"
                )
              }
            >
              <NavLink
               to='/technology'
               $hasDropdown={true} 
                className={location.pathname === "/technology" ? "active" : ""}
              >
                Technology
              </NavLink>
              <DropdownMenuShifted>
                <Card>
                  <CardImage
                    style={{ backgroundImage: `url(${technologyImage})` }}
                  />
                  <CardContent>
                    <TechnologyContent>
                      <GridItem>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Backend Technologies</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                        <ColumnItem>
                          Node.js | PHP | .Net | Python | Java
                        </ColumnItem>
                      </GridItem>
                      <GridItem>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Front-end Technologies</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                        <ColumnItem>
                          React.js | Angular | Vue.js | Next | HTML5 |
                          TypeScript
                        </ColumnItem>
                      </GridItem>
                      <GridItem>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Mobile Technologies</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                        <ColumnItem>
                          Flutter | React Native | Swift | Kotlin | iOS |
                          Android | Iconic | Xamarin
                        </ColumnItem>
                      </GridItem>
                      <GridItem>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Frameworks We Use</ColumnTitle>
                        <ColumnItem>
                          Engineer your app to perfection with our
                          state-of-the-art frameworks that deliver exceptional
                          outcomes
                        </ColumnItem>
                        <ColumnItem>
                          MERN | MEAN | DotNet Core | DotNet MVC | Django |
                          Flask | Spring | Codeignitor | WordPress | Hibernate |
                          Magento
                        </ColumnItem>
                      </GridItem>
                      <GridItem>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Cloud Technologies</ColumnTitle>
                        <ColumnItem>
                          Build high-performing architectures for the backend of
                          your app
                        </ColumnItem>
                        <ColumnItem>
                          AWS | Azure | Google Cloud |IBM Watson | Alibaba |
                          MyHostLord
                        </ColumnItem>
                      </GridItem>
                    </TechnologyContent>
                  </CardContent>
                </Card>
              </DropdownMenuShifted>
            </NavItem>
            <NavItem
              isDropdownOpen={activeDropdown === "hire"}
              onClick={() =>
                setActiveDropdown(activeDropdown === "hire" ? null : "hire")
              }
            >
              <NavLink
              to='/hire'
              $hasDropdown={true} 
                className={location.pathname === "/hire" ? "active" : ""}
              >
                Hire
              </NavLink>
              <DropdownMenuShifted>
                <Card>
                  <CardImage style={{ backgroundImage: `url(${hireImage})` }} />
                  <CardContent>
                    <HireContent>
                      <GridItemh>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Trending</ColumnTitle>
                        <ColumnItem>Software Developers</ColumnItem>
                        <ColumnItem>Full Stack Developer</ColumnItem>
                        <ColumnItem>Mean stack Developer</ColumnItem>
                        <ColumnItem>MERN Stack Developer</ColumnItem>
                        <ColumnItem>Web Developers</ColumnItem>
                        <ColumnItem>Blockchain Developers</ColumnItem>
                        <ColumnItem>DevOps Engineers</ColumnItem>
                        <ColumnItem>Software tester</ColumnItem>
                        <ColumnItem>Service now Developers</ColumnItem>
                        <ColumnItem>UI UX Designers</ColumnItem>
                        <ColumnItem>AWS Developers</ColumnItem>
                        <ColumnItem>Open source Developers</ColumnItem>
                        <ColumnItem>SaaS Developers</ColumnItem>
                        <ColumnItem>Open Source Developers</ColumnItem>
                      </GridItemh>
                      <GridItemh>
                         <ColumnTitle  onClick={handleColumnTitleClick}>E-commerce Developers</ColumnTitle>
                        <ColumnItem>Wordpress Developer</ColumnItem>
                        <ColumnItem>Magento Developer</ColumnItem>
                        <ColumnTitle style={{ marginTop: "20px" }}>
                          Frontend Developers
                        </ColumnTitle>
                        <ColumnItem>AngularJS Developers</ColumnItem>
                        <ColumnItem>ReactJS Developer</ColumnItem>
                        <ColumnItem>VueJS Developer</ColumnItem>
                        <ColumnItem>JavaScript Developer</ColumnItem>
                        <ColumnItem>NextJS Developers</ColumnItem>
                        <ColumnItem>TypeScript Developers</ColumnItem>
                        <ColumnItem>ViteJS Developers</ColumnItem>
                        <ColumnItem>Tailwind CSS Developers</ColumnItem>
                        <ColumnItem>HTML5 Developers</ColumnItem>
                      </GridItemh>
                      <GridItemh>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Backend Developers</ColumnTitle>
                        <ColumnItem>ASP.NET Developers</ColumnItem>
                        <ColumnItem>PHP Developer</ColumnItem>
                        <ColumnItem>Java Developer</ColumnItem>
                        <ColumnItem>Python Developer</ColumnItem>
                        <ColumnItem>NodeJS Developers</ColumnItem>
                        <ColumnItem>ExpressJS Developers</ColumnItem>
                        <ColumnItem>SpringBoot Developers</ColumnItem>
                        <ColumnItem>Django Developers</ColumnItem>
                        <ColumnItem>Ruby on Rails</ColumnItem>
                      </GridItemh>
                    </HireContent>
                  </CardContent>
                </Card>
              </DropdownMenuShifted>
            </NavItem>
            <NavItem
  className="solutions"
  isDropdownOpen={activeDropdown === "solutions"}
  onClick={() => setActiveDropdown(null)}
>
  <NavLink
    to='#'
    $hasDropdown={true}
    className={location.pathname === "/solutions" ? "active" : ""}
  >
    Solutions
  </NavLink>
              <DropdownMenuShifted>
                <Card>
                  <CardImage
                    style={{ backgroundImage: `url(${solutionsImage})` }}
                  />
                  <CardContent>
                    <SolutionsContent>
                      <GridItemh>
                         <ColumnTitle  onClick={handleColumnTitleClick}>Food Delivery App like Zomato</ColumnTitle>
                        <ColumnItem>
                          Photo Sharing App like Instagram
                        </ColumnItem>
                        <ColumnItem>Video Sharing App Like Youtube</ColumnItem>
                        <ColumnItem>E-learning App like Coursera</ColumnItem>
                        <ColumnItem>Cost to Develop A chat App</ColumnItem>
                        <ColumnItem>Taxi App like Uber</ColumnItem>
                        <ColumnItem>E-Learning App like Byjus</ColumnItem>
                        <ColumnItem>
                          E- Learning Appp like Udemy/Lynda
                        </ColumnItem>
                        <ColumnItem>Healthcare App like Zocdoc</ColumnItem>
                        <ColumnItem>Sports Betting app like Bet365</ColumnItem>
                      </GridItemh>
                      <GridItemh>
                         <ColumnTitle  onClick={handleColumnTitleClick}>
                          Video Streaming App Like Netflix
                        </ColumnTitle>
                        <ColumnItem>Healthcare App Like Practo</ColumnItem>
                        <ColumnItem>Food Delivery App like UberEats</ColumnItem>
                        <ColumnItem>Sports Betting App Like Dream11</ColumnItem>
                        <ColumnItem>
                          Grocery Delivery App like Dream11
                        </ColumnItem>
                        <ColumnItem>Music Video App Like Tiktok</ColumnItem>
                        <ColumnItem>
                          E-commerce App/ Website Like Amazon
                        </ColumnItem>
                        <ColumnItem>Dating App Like Tinder</ColumnItem>
                        <ColumnItem>Cost to Make an App like Airbnb</ColumnItem>
                      </GridItemh>
                    </SolutionsContent>
                  </CardContent>
                </Card>
              </DropdownMenuShifted>
            </NavItem>
            <NavItem>
              <NavLink3 to="/work">Work</NavLink3>
            </NavItem>
            <MenuContainer className="menu-container" ref={menuRef}>
              <MenuButton>
                MENU <MenuIcon src={menuIcon} alt="Menu" />
              </MenuButton>
              <MenuDropdown>
                <MenuCard
                  onQuoteClick={handleQuoteClick}
                  onProfileClick={handleProfileClick}
                  onNavigate={handleMenuClick}
                />
              </MenuDropdown>
            </MenuContainer>
          </NavMenu>
        
        </NavContent>
      </MainNav>

      {showQuoteModal && (
        <ModalOverlay onClick={() => setShowQuoteModal(false)}>
          <ModalContent ref={modalRef} onClick={handleModalClick}>
            <ModalCloseButton onClick={() => setShowQuoteModal(false)}>
              <X />
            </ModalCloseButton>
            <ModalTitle>
              Add your information, and we will contact you.
            </ModalTitle>
            <ModalForm onSubmit={(e) => handleSubmit(e, "quote")}>
              <ModalInput
                type="text"
                name="name"
                placeholder="Name*"
                required
              />
              <ModalInput
                type="email"
                name="email"
                placeholder="Email*"
                required
              />
              <ModalInput
                type="tel"
                name="phone"
                placeholder="Contact Number*"
                required
              />
              <ModalSelect name="budget" required>
                <option value="">Your Budget</option>
                <option value="0-5000">$0 - $5,000</option>
                <option value="5000-10000">$5,000 - $10,000</option>
                <option value="10000+">$10,000+</option>
              </ModalSelect>
              <ModalSelect name="interest" required>
                <option value="">Looking For</option>
                <option value="Team Extension">Team Extension</option>
                <option value="Product Engineering">Product Engineering</option>
                <option value="Web Development">Web Development</option>
                <option value="Mobility Solutions">Mobility Solutions</option>
                <option value="Emerging Tech">Emerging Tech</option>
                <option value="E-Commerce">E-Commerce</option>
                <option value="Cyber Security">Cyber Security</option>
                <option value="Cloud And Devops">Cloud And Devops</option>
                <option value="Quality Analyst">Quality Analyst</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="PR & Media">PR & Media</option>
              </ModalSelect>
              <ModalSelect name="source" required>
                <option value="">How did you learn about us</option>
                <option value="search">Search Engine</option>
                <option value="social-media">Social Media</option>
                <option value="referral">Referral</option>
                <option value="other">Other</option>
              </ModalSelect>
              <ModalSubmitButton style={{position: "relative", left: "70%", transform: "translateX(-50%)"}} type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </ModalSubmitButton>
            </ModalForm>
          </ModalContent>
        </ModalOverlay>
      )}

      {showProfileModal && (
        <ModalOverlay onClick={() => setShowProfileModal(false)}>
          <ModalContent ref={modalRef} onClick={handleModalClick}>
            <ModalCloseButton onClick={() => setShowProfileModal(false)}>
              <X />
            </ModalCloseButton>
            <ModalTitle>Explore corporate profile</ModalTitle>
            <ModalForm onSubmit={(e) => handleSubmit(e, "profile")}>
              <ModalInput
                type="text"
                name="name"
                placeholder="Name*"
                required
              />
              <ModalInput
                type="email"
                name="email"
                placeholder="Email*"
                required
              />
              <ModalInput
                type="tel"
                name="phone"
                placeholder="Contact Number*"
                required
              />
              <ModalSelect name="budget" required>
                <option value="">Your Budget</option>
                <option value="0-5000">$0 - $5,000</option>
                <option value="5000-10000">$5,000 - $10,000</option>
                <option value="10000+">$10,000+</option>
              </ModalSelect>
              <ModalSelect name="interest" required>
                <option value="">Looking For</option>
                <option value="Team Extension">Team Extension</option>
                <option value="Product Engineering">Product Engineering</option>
                <option value="Web Development">Web Development</option>
                <option value="Mobility Solutions">Mobility Solutions</option>
                <option value="Emerging Tech">Emerging Tech</option>
                <option value="E-Commerce">E-Commerce</option>
                <option value="Cyber Security">Cyber Security</option>
                <option value="Cloud And Devops">Cloud And Devops</option>
                <option value="Quality Analyst">Quality Analyst</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="PR & Media">PR & Media</option>
              </ModalSelect>
              <ModalSelect name="source" required>
                <option value="">How did you learn about us</option>
                <option value="search">Search Engine</option>
                <option value="social-media">Social Media</option>
                <option value="referral">Referral</option>
                <option value="other">Other</option>
              </ModalSelect>
              <ModalSubmitButton style={{position: "relative", left: "80%", transform: "translateX(-50%)"}} type="submit" disabled={isSubmitting}>
                Explore Profile
              </ModalSubmitButton>
            </ModalForm>
          </ModalContent>
        </ModalOverlay>
      )}

      {showSuccess && (
        <SuccessModal>
          <SuccessContent ref={modalRef}>
            <div style={{ textAlign: "center" }}>
              <svg width="44" height="44" viewBox="0 0 64 64" fill="none">
                <circle cx="32" cy="32" r="32" fill="#4CAF50" />
                <path d="M20 32L28 40L44 24" stroke="white" strokeWidth="4" />
              </svg>
              <ModalTitle>Details Submitted successfully</ModalTitle>
              <ModalSubmitButton onClick={() => setShowSuccess(false)}>
                Done
              </ModalSubmitButton>
            </div>
          </SuccessContent>
        </SuccessModal>
      )}
    </HeaderWrapper>
  );
};

export default Header;
