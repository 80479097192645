import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowLeft, ArrowRight } from 'lucide-react';

const BlogDetailsContainer = styled.div`
  max-width: 1000px;
  margin: 120px auto 60px;
  padding: 0 20px;
`;

const HeroBanner = styled.div`
  width: 100%;
  height: 300px;
  background: linear-gradient(135deg, #015bcc 0%, #2f69b1 100%);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const BlogHeader = styled.div`
  margin-bottom: 40px;
`;

const BlogTitle = styled.h1`
  font-size: 44px;
  color: #434343;
  line-height: 1.3;
  margin-bottom: 24px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const BlogMeta = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  color: #666;
  font-size: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
`;

const MetaItem = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MainContent = styled.div`
  font-size: 18px;
  line-height: 1.8;
  color: #434343;
  margin-bottom: 40px;
  font-family: "Poppins", sans-serif;
`;

const Paragraph = styled.p`
  margin-bottom: 24px;
  font-weight: 400;
`;

const SubHeading = styled.h2`
  font-size: 28px;
  color: #434343;
  margin: 40px 0 20px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const CodeBlock = styled.pre`
  background: #f5f7f9;
  padding: 20px;
  border-radius: 8px;
  overflow-x: auto;
  margin: 20px 0;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
`;

const BulletList = styled.ul`
  margin: 20px 0;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 12px;
`;

const TagsSection = styled.div`
  margin: 40px 0;
  padding: 30px;
  background: #f8faff;
  border-radius: 12px;
`;

const TagsTitle = styled.h3`
  font-size: 20px;
  color: #434343;
  margin-bottom: 16px;
  font-weight: 600;
`;

const TagsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const Tag = styled.span`
  background: white;
  color: #015bcc;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #e5e9ff;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background: #015bcc;
    color: white;
  }
`;

const RelatedPosts = styled.div`
  margin-top: 60px;
`;

const RelatedTitle = styled.h2`
  font-size: 32px;
  color: #434343;
  margin-bottom: 30px;
  font-weight: 700;
`;

const RelatedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const RelatedCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const RelatedImage = styled.div`
  width: 100%;
  height: 200px;
  background: ${props => props.gradient || 'linear-gradient(135deg, #015bcc 0%, #2f69b1 100%)'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RelatedContent = styled.div`
  padding: 24px;
`;

const RelatedCategory = styled.span`
  color: #015bcc;
  font-size: 14px;
  font-weight: 600;
`;

const RelatedHeading = styled.h3`
  font-size: 20px;
  color: #434343;
  margin: 12px 0;
  line-height: 1.4;
`;

const RelatedMeta = styled.div`
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  padding-top: 40px;
  border-top: 1px solid #eee;
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: #015bcc;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 0;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateX(${props => props.$isNext ? '5px' : '-5px'});
  }
`;

const blogDetails = {
  1: {
    title: 'Enterprise Solutions: Building Scalable Applications',
    content: [
      {
        type: 'paragraph',
        text: 'Building enterprise-scale applications requires a comprehensive understanding of both business requirements and technical architecture. This guide explores the key aspects of creating scalable, maintainable enterprise solutions.'
      },
      {
        type: 'heading',
        text: 'Key Architectural Principles'
      },
      {
        type: 'list',
        items: [
          'Scalability from day one',
          'Modularity and loose coupling',
          'Security by design',
          'Performance optimization',
          'Monitoring and observability'
        ]
      },
      {
        type: 'heading',
        text: 'Implementation Best Practices'
      },
      {
        type: 'paragraph',
        text: 'When implementing enterprise solutions, following established patterns and practices is crucial for long-term success. Here are some key considerations:'
      },
      {
        type: 'code',
        language: 'typescript',
        content: `
interface EnterpriseConfig {
  scaling: {
    autoScale: boolean;
    minInstances: number;
    maxInstances: number;
  };
  security: {
    authentication: AuthMethod;
    encryption: EncryptionLevel;
  };
  monitoring: {
    metrics: string[];
    alerting: AlertConfig;
  };
}
        `
      }
    ]
  },
  2: {
    title: 'AI Integration in Modern Web Development',
    content: [
      {
        type: 'paragraph',
        text: 'Artificial Intelligence is revolutionizing web development, enabling more intelligent and responsive applications. This article explores practical approaches to integrating AI capabilities into modern web applications.'
      },
      {
        type: 'heading',
        text: 'AI Integration Patterns'
      },
      {
        type: 'list',
        items: [
          'Natural Language Processing for enhanced search',
          'Recommendation systems for personalized content',
          'Computer vision for image processing',
          'Predictive analytics for user behavior'
        ]
      },
      {
        type: 'code',
        language: 'javascript',
        content: `
const AI_CONFIG = {
  modelEndpoint: 'https://api.ai-service.com/v1/predict',
  parameters: {
    temperature: 0.7,
    maxTokens: 100,
    topP: 0.9
  },
  caching: {
    enabled: true,
    ttl: 3600
  }
};
        `
      }
    ]
  },
  3: {
    title: 'Cloud Architecture Best Practices for 2024',
    content: [
      {
        type: 'paragraph',
        text: 'Cloud architecture continues to evolve with new best practices emerging for optimization, security, and performance. This guide covers the latest trends and recommendations for cloud architecture in 2024.'
      },
      {
        type: 'heading',
        text: 'Modern Cloud Architecture Patterns'
      },
      {
        type: 'list',
        items: [
          'Serverless-first approach',
          'Multi-cloud strategy',
          'Edge computing integration',
          'Zero-trust security model',
          'Cost optimization techniques'
        ]
      },
      {
        type: 'code',
        language: 'yaml',
        content: `
cloud_config:
  region: auto
  scaling:
    min_instances: 2
    max_instances: 10
    metrics:
      - cpu_utilization
      - memory_usage
  security:
    encryption: AES256
    access_control: RBAC
        `
      }
    ]
  },
  4: {
    title: 'Mobile App Development: Native vs Cross-platform',
    content: [
      {
        type: 'paragraph',
        text: 'The choice between native and cross-platform development is crucial for mobile app success. This comprehensive guide examines the pros and cons of each approach and helps you make an informed decision.'
      },
      {
        type: 'heading',
        text: 'Comparison Factors'
      },
      {
        type: 'list',
        items: [
          'Performance and user experience',
          'Development speed and cost',
          'Platform-specific features',
          'Long-term maintenance',
          'Team expertise requirements'
        ]
      },
      {
        type: 'code',
        language: 'jsx',
        content: `
// React Native Example
const MobileApp = () => {
  const [platform] = useState(Platform.OS);
  
  return (
    <View style={styles.container}>
      <Text>
        Running on {platform}
      </Text>
      {platform === 'ios' && (
        <ApplePayButton />
      )}
      {platform === 'android' && (
        <GooglePayButton />
      )}
    </View>
  );
};
        `
      }
    ]
  },
  5: {
    title: 'Cybersecurity Measures for Enterprise Applications',
    content: [
      {
        type: 'paragraph',
        text: 'Enterprise application security requires a comprehensive approach to protect against evolving threats. This guide covers essential security measures and best practices for enterprise applications.'
      },
      {
        type: 'heading',
        text: 'Security Implementation'
      },
      {
        type: 'list',
        items: [
          'Authentication and authorization',
          'Data encryption at rest and in transit',
          'Security monitoring and logging',
          'Vulnerability assessment',
          'Incident response planning'
        ]
      },
      {
        type: 'code',
        language: 'typescript',
        content: `
interface SecurityConfig {
  authentication: {
    method: 'JWT' | 'OAuth2';
    expiry: number;
    refresh: boolean;
  };
  encryption: {
    algorithm: string;
    keySize: number;
    rotation: number;
  };
  monitoring: {
    logLevel: 'INFO' | 'WARN' | 'ERROR';
    metrics: string[];
  };
}
        `
      }
    ]
  },
  6: {
    title: 'Microservices Architecture: A Complete Guide',
    content: [
      {
        type: 'paragraph',
        text: 'Microservices architecture has become the standard for building scalable, maintainable applications. This comprehensive guide covers everything from basic concepts to advanced implementation strategies.'
      },
      {
        type: 'heading',
        text: 'Core Concepts'
      },
      {
        type: 'list',
        items: [
          'Service decomposition',
          'Inter-service communication',
          'Data management',
          'Deployment strategies',
          'Service discovery'
        ]
      },
      {
        type: 'code',
        language: 'yaml',
        content: `
service:
  name: user-service
  version: 1.0.0
  dependencies:
    - auth-service
    - notification-service
  endpoints:
    - /users
    - /users/{id}
  database:
    type: postgresql
    sharding: true
        `
      }
    ]
  }
};

const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const numericId = parseInt(id);
  
  const blog = blogDetails[numericId];
  const nextBlog = blogDetails[numericId + 1];
  const prevBlog = blogDetails[numericId - 1];

  if (!blog) {
    return <div>Blog not found</div>;
  }

  const renderContent = (content) => {
    return content.map((section, index) => {
      switch (section.type) {
        case 'paragraph':
          return <Paragraph key={index}>{section.text}</Paragraph>;
        case 'heading':
          return <SubHeading key={index}>{section.text}</SubHeading>;
        case 'list':
          return (
            <BulletList key={index}>
              {section.items.map((item, i) => (
                <ListItem key={i}>{item}</ListItem>
              ))}
            </BulletList>
          );
        case 'code':
          return <CodeBlock key={index}>{section.content}</CodeBlock>;
        default:
          return null;
      }
    });
  };

  return (
    <BlogDetailsContainer>
      <HeroBanner>
        <svg width="120" height="120" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.2"/>
          <path d="M12 6v12M6 12h12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>
      </HeroBanner>

      <BlogHeader>
        <BlogTitle>{blog.title}</BlogTitle>
        <BlogMeta>
          <MetaItem>Category: Technology</MetaItem>
          <MetaItem>November 15, 2024</MetaItem>
          <MetaItem>8 min read</MetaItem>
        </BlogMeta>
        </BlogHeader>

      <MainContent>
        {renderContent(blog.content)}
      </MainContent>

      <TagsSection>
        <TagsTitle>Related Topics</TagsTitle>
        <TagsGrid>
          <Tag>Technology</Tag>
          <Tag>Development</Tag>
          <Tag>Best Practices</Tag>
          <Tag>Architecture</Tag>
          <Tag>Solutions</Tag>
        </TagsGrid>
      </TagsSection>

      <RelatedPosts>
        <RelatedTitle>Related Articles</RelatedTitle>
        <RelatedGrid>
          {prevBlog && (
            <RelatedCard onClick={() => navigate(`/blog/${numericId - 1}`)}>
              <RelatedImage gradient="linear-gradient(135deg, #015bcc 0%, #2f69b1 100%)">
                <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.2"/>
                  <path d="M12 6v12M6 12h12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </RelatedImage>
              <RelatedContent>
                <RelatedCategory>Technology</RelatedCategory>
                <RelatedHeading>{prevBlog.title}</RelatedHeading>
                <RelatedMeta>
                  <span>November 15, 2024</span>
                  <span>5 min read</span>
                </RelatedMeta>
              </RelatedContent>
            </RelatedCard>
          )}
          {nextBlog && (
            <RelatedCard onClick={() => navigate(`/blog/${numericId + 1}`)}>
              <RelatedImage gradient="linear-gradient(135deg, #2f69b1 0%, #015bcc 100%)">
                <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.2"/>
                  <path d="M12 6v12M6 12h12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </RelatedImage>
              <RelatedContent>
                <RelatedCategory>Technology</RelatedCategory>
                <RelatedHeading>{nextBlog.title}</RelatedHeading>
                <RelatedMeta>
                  <span>November 15, 2024</span>
                  <span>5 min read</span>
                </RelatedMeta>
              </RelatedContent>
            </RelatedCard>
          )}
        </RelatedGrid>
      </RelatedPosts>

      <NavigationButtons>
        {prevBlog && (
          <NavButton onClick={() => navigate(`/blog/${numericId - 1}`)}>
            <ArrowLeft size={20} /> Previous Article
          </NavButton>
        )}
        {nextBlog && (
          <NavButton $isNext onClick={() => navigate(`/blog/${numericId + 1}`)}>
            Next Article <ArrowRight size={20} />
          </NavButton>
        )}
      </NavigationButtons>
    </BlogDetailsContainer>
  );
};

export default BlogDetails;