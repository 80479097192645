import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import work from "../assets/images/work.png";
import award_1 from "../assets/images/award_1.svg";
import award_2 from "../assets/images/award_2.svg";
import award_3 from "../assets/images/award_3.svg";
import award_4 from "../assets/images/award_4.svg";
import award_5 from "../assets/images/award_5.svg";
import award_6 from "../assets/images/award_6.svg";
import Businesses_1 from "../assets/images/Businesses_1.svg";
import Businesses_2 from "../assets/images/Businesses_2.svg";
import Businesses_3 from "../assets/images/Businesses_3.svg";
import Businesses_4 from "../assets/images/Businesses_4.svg";
import Businesses_5 from "../assets/images/Businesses_5.svg";
import Businesses_6 from "../assets/images/Businesses_6.svg";
import Businesses_7 from "../assets/images/Businesses_7.svg";
import Businesses_8 from "../assets/images/Businesses_8.svg";
import Businesses_9 from "../assets/images/Businesses_9.svg";
import Businesses_10 from "../assets/images/Businesses_10.svg";
import Businesses_11 from "../assets/images/Businesses_11.svg";
import Businesses_12 from "../assets/images/Businesses_12.svg";
import Customers from "../assets/images/Customers.png";
import trusted_partner_1 from "../assets/images/trusted_partner_1.svg";
import trusted_partner_2 from "../assets/images/trusted_partner_2.svg";
import trusted_partner_3 from "../assets/images/trusted_partner_3.svg";
import trusted_partner_4 from "../assets/images/trusted_partner_4.svg";
import trusted_partner_5 from "../assets/images/trusted_partner_5.svg";
import trusted_partner_6 from "../assets/images/trusted_partner_6.svg";
import trusted_partner_7 from "../assets/images/trusted_partner_7.svg";
import trusted_partner_8 from "../assets/images/trusted_partner_8.svg";
import whatsapp from "../assets/images/whatsapp.png";
import practically from "../assets/images/practically.png";
import sumitian from "../assets/images/sumitian.png";
import dronekarna from "../assets/images/drone.png";
import dooper from "../assets/images/dooper.png";
import womb from "../assets/images/womb.png";
import reroutee from "../assets/images/reeroute.png";
import sam from "../assets/images/shareauto.png";
import appreaze from "../assets/images/appreaz.png";
import inte from "../assets/images/inre.png";
import samtosh from "../assets/images/triproperty.png";
import mountain from "../assets/images/mountain.png";
import stock from "../assets/images/stock.png";
import multimeds from "../assets/images/multimeds.png";
import writely from "../assets/images/writely.png";
import sacred from "../assets/images/sacred.png";
import projectus from "../assets/images/projectus.png";
import milibazar from "../assets/images/milibazar.png";
import html from "../assets/images/html.svg";
import css from "../assets/images/css.svg";
import jquery from "../assets/images/jquery.svg";
import bootstrap from "../assets/images/bootstrap.svg";
import php from "../assets/images/php.svg";
import ajax from "../assets/images/ajax.svg";
import mysql from "../assets/images/mysql.svg";
import laravel from "../assets/images/laravel.svg";
import positive from "../assets/images/positive.png";
import negative from "../assets/images/negative.png";

const Container = styled.div`
  max-width: 65vw;
  margin: 0 auto;
  padding: 0 20px;
  font-family: "Poppins";
  
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px;
    margin-top: 50px;
  }
`;

const Section = styled.section`
  margin-bottom: 30px;
  padding: 20px 0;
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding: 20px 0;
  }
`;

const OurWorkSection = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const OurWorkContent = styled.div`
  flex: 1;
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;
const Title = styled.h2`
  font-size: 46px;
  background: linear-gradient(
    270deg,
    #000000 60%,
    #1a1a1a 70%,
    #0072ff 85%,
    #2f69b1 100%
  );
  background-size: 200% 200%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${gradientAnimation} 3s ease infinite;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 62.4px;
  text-align: center;
  width: 100%;
  display: block;
  margin: 0 auto 20px auto;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 1.3;
    margin-bottom: 15px;
    padding: 0 20px;
  }
`;

const Description = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 30px;
  color: #4e4e4e;
  
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.5;
  }
`;

const OurWorkImage = styled.img`
  max-width: 50%;
  
  @media (max-width: 768px) {
    max-width: 80%;
    margin-top: 20px;
  }
`;

const ProjectsGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectCard = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
  border: 1px solid #e1e1e1;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  }
`;

const ProjectContent = styled.div`
  padding: 20px;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
`;

const ProjectTitle = styled.h3`
  font-size: 20px;
  color: #434343;
  margin: 0 0 10px 0;
  font-weight: 500;
  line-height: 26px;
`;

const ProjectDescription = styled.p`
  font-size: 14px;
  color: #4e4e4e;
  line-height: 1.6;
  margin-bottom: 15px;
  max-height: ${props => props.expanded ? 'none' : '65px'};
  overflow: hidden;
  position: relative;
  cursor: pointer;
`;

const DescriptionToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  cursor: pointer;
`;

const ToggleIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const TechStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
`;

const TechIcon = styled.img`
  width: 25px;
  height: 25px;
`;

const ToggleButton = styled.button`
  background-color: #015bcc;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  transition: background 0.3s ease;

  &:hover {
    background: linear-gradient(to right, #0072ff 0%, #2f69b1 100%);
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 16px;
    padding: 12px 20px;
  }
`;



const AwardImage = styled.img`
  height: 80px;
  width: auto;
  margin-bottom: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 30px;
  }
`;

const TrustedLogo = styled.img`
  height: 60px;
  width: auto;
  margin: 0 auto;
  cursor: pointer;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    height: 40px;
  }
`;

const AwardsDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;
const AwardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin: 20px 0;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
`;

const Section0 = styled.section`
  padding: 0px 0;
  @media (max-width: 768px) {
    padding: 0px 0;
  }
`;


const BusinessesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin-top: 25px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const BusinessIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const BusinessName = styled.p`
  font-size: 14px;
  margin-top: 10px;
`;

const BusinessItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (min-width: 768px) {
    &:hover {
      transform: translateY(-5px) scale(1.05);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      border-radius: 10px;
      padding: 10px;
    }
  }
`;

const TestimonialsSection = styled(Section)`
  text-align: center;
`;

const TestimonialCard = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 20px;
  margin-top: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const TestimonialImage = styled.img`
  width: 300px;
  margin-right: 20px;
  
  @media (max-width: 768px) {
   display: none;
  }
`;

const TestimonialContent = styled.div`
  text-align: left;
  
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const TestimonialText = styled.p`
  font-size: 16px;
  color: #4e4e4e;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const TestimonialAuthor = styled.h4`
  font-size: 18px;
  color: #434343;
  margin: 0 0 5px 0;
`;

const TestimonialPosition = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
`;

const TrustedSection = styled(Section)`
  text-align: center;
  margin-bottom: 30px;
`;

const TrustedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin: 20px 0;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;



const projectList = [
  { 
    image: sumitian, 
    title: "Sumitian",
    description: "Sumit EdTech, a prominent coaching institute based in Bihar, embarked on a mission to enhance its educational offerings beyond live classes. The primary business goal was to create a robust Learning Management System (LMS) through a dedicated application.",
  },
  { 
    image: practically, 
    title: "Practically",
    description: "Practically, an ad tech application designed for K-6 to K-12 students embarked on a mission to revolutionize vocational education. The primary business goal was to create a comprehensive platform that seamlessly integrates virtual reality, mobile application, and chatbot technologies.",
  },
  { 
    image: dooper, 
    title: "Dooper",
    description: "Dooper embarked on a mission to redefine healthcare services by providing a comprehensive application catering to all hospital needs. The primary objective was to create a user-friendly platform that seamlessly integrates ambulance booking, appointments with health assistants and doctors.",
  },
  { 
    image: womb, 
    title: "WOMB",
    description: "WOMB, a custom website and application, was conceptualized to offer comprehensive support to individuals navigating the transformative journey of pregnancy. The business goal was to create a holistic platform that not only tracked essential metrics but also provided coaching.",
  },
  { 
    image: reroutee, 
    title: "Reeroute",
    description: "The Reeroute Trucking Application aims to revolutionize the logistics industry by providing a comprehensive solution that optimizes trucking operations for transporters, consignors, vehicle owners, drivers, administrators, and sales teams.",
  },
  { 
    image: inte, 
    title: "INRE",
    description: "INRE Global aspires to revolutionize the real estate sector by creating a user-friendly platform that streamlines the buying and selling of properties for both customers and administrators.",
  },
  { 
    image: samtosh, 
    title: "Santosh Investment",
    description: "Santosh aims to revolutionize the real estate investment experience by providing a seamless platform for investors to effortlessly manage their portfolios. With flexible registration, secure payment integration, and automatic monthly payouts.",
  },
  {
    image:stock,
    title:"StockChamp: Fantasy Stock Gaming",
    description:"StockChamp aims to revolutionize the stock market experience by providing users with an immersive fantasy gaming platform. The primary business goal is to empower users with a comprehensive application where they can learn about stocks, manage portfolios, and engage in interactive gameplay. Through seamless sign-up processes and intuitive interfaces, StockChamp strives to make stock market participation accessible to all, fostering financial literacy and empowerment among users."
  },
  {
    image:multimeds,
    title:"Multimedes",
    description:"Multimedes strives to revolutionize healthcare by developing a dynamic online platform showcasing its generic drugs. Through the power of digital technology, the business aims to empower customers with easily accessible information, fostering informed decision-making about their healthcare needs. The platform endeavors to streamline the purchasing process, providing intuitive interfaces and secure payment processing for enhanced convenience. Multimedes seeks to bridge the gap between consumers and pharmaceutical products, promoting proactive healthcare management and long-term customer loyalty. Ultimately, the business aspires to be a comprehensive showcase, transforming the pharmaceutical landscape through innovation and accessibility."
  },
  {
    image:writely,
    title:"Wriety",
    description:"Wriety, a dynamic content-driven platform, sought to enhance its online presence and engagement by developing a website controlled by a Learning Management System (LMS) using WordPress. The primary goal was to enable dynamic content updates and foster healthy competition among users for a superior website experience. This case study delves into the challenges faced and the innovative solutions implemented to achieve these objectives."
  },
  {
    image:sacred,
    title:"Sacred Groves",
    description:"Sacred Groves Knowledge Base aimed to enhance its online presence by creating a dynamic content management system (CMS). The business goal was to provide the client with a platform to dynamically publish news, information about the environment, and a gifting section with occasion-based cards. The CMS solution needed to be user-friendly, allowing the client to update content seamlessly and make the gifting section dynamic. The project focused on developing a custom CMS using WordPress, ensuring easy content publication and integration with the existing system."
  },
  {
    image:projectus,
    title:"Project Us",
    description:"Project Us aimed to create a comprehensive marketplace for real estate-related services and vendors. The business goal was to develop a mobile application for users to browse services, schedule bookings, and make payments. Additionally, a partner application was designed for service providers to manage bookings, and an admin application for overall platform management. The project also included the development of an informative website. The primary objective was to streamline and enhance the user experience for both customers and service providers in the real estate services domain."
  },
  {
    image:milibazar,
    title:"MiliBazar",
    description:"MiliBazar, an eCommerce platform, endeavors to revolutionize the shopping experience by bridging the gap between buyers and local vendors. The primary business goal is to establish a comprehensive marketplace where local vendors can effectively showcase their products. Simultaneously, the platform aims to offer buyers a seamless browsing and purchasing experience, enhancing user satisfaction through cutting-edge technology."
  }
];
const Subtitle = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
  font-family: "Poppins";

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.5;
    padding: 0 20px;

    br {
      display: none;
    }
  }
`;



const AwardsSection = styled(Section)`
  text-align: center;
  visibility: visible !important;
  opacity: 1 !important;
  position: relative;
  z-index: 1;
`;

const BusinessesSection = styled(Section)`
  text-align: center;
  visibility: visible !important;
  opacity: 1 !important;
  position: relative;
  z-index: 1;
`;

const TestimonialSection = styled(Section)`
  text-align: center;
  visibility: visible !important;
  opacity: 1 !important;
  position: relative;
  z-index: 1;
`;

const AwardsDot = styled.span`
  width: 10px;
  cursor: pointer;
  height: 10px;
  background-color: ${(props) => (props.active ? "#434343" : "#6C6C6C")};
  border-radius: 50%;
`;
const businessReasons = [
  { icon: Businesses_1, text: "SEO and Digital Marketing" },
  { icon: Businesses_2, text: "4 months + free post launch support" },
  { icon: Businesses_3, text: "Non-Disclosure Agreement" },
  { icon: Businesses_4, text: "100% value for the money" },
  { icon: Businesses_5, text: "Professional coding and version controls" },
  { icon: Businesses_6, text: "Flexible Payment Plans" },
  { icon: Businesses_7, text: "Real-time project tracking tools" },
  { icon: Businesses_8, text: "Custom Tailored support | AMC Plans" },
  { icon: Businesses_9, text: "Quality Assurance and Unit Test Cases" },
  { icon: Businesses_10, text: "Penalty enforcement in case of Delivery Delay" },
  { icon: Businesses_11, text: "In-depth analysis of revenue business Models" },
  { icon: Businesses_12, text: "Mind Maps and video demos" }
];

const Work = () => {
  const [showAll, setShowAll] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });
  const [activeDot, setActiveDot] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const testimonials = [
    {
      text: "Jethi Tech Solutions has been a game-changer for our business. Their team delivered a top-notch solution that exceeded our expectations. We're thrilled with the results!",
      author: "Mark Rise",
      position: "CEO, Founder",
    },
    {
      text: "Since implementing Jethi Tech Solutions, we've seen a 25% increase in website traffic and a 15% boost in conversions. The team at Jethi Tech Solutions truly understands our business needs.",
      author: "Jane Doe",
      position: "Marketing Director",
    },
    {
      text: "Working with Jethi Tech Solutions felt like a true partnership. They were responsive, proactive, and always willing to go the extra mile. We highly recommend their services.",
      author: "John Smith",
      position: "CEO, Founder",
    },
  ];

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe && activeDot < testimonials.length - 1) {
      setActiveDot((prev) => prev + 1);
    }

    if (isRightSwipe && activeDot > 0) {
      setActiveDot((prev) => prev - 1);
    }

    setTouchStart(null);
    setTouchEnd(null);
  };
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
        ease: "easeOut"
      }
    })
  };

  const toggleDescription = (index) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const displayedProjects = showAll ? projectList : projectList.slice(0, 6);
  const techIcons = [html, css, jquery, bootstrap, php, ajax, mysql, laravel];

 const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true
      }
    }
  ]
};
  return (
    <Container>
      <OurWorkSection>
        <OurWorkContent>
          <Title>
            Our <span>Work</span>
          </Title>
          <Description>
            Jethi Tech Solutions creates inspirational designs and robust solutions for Web and mobile apps. We combine our expertise in tech & design to craft innovative digital solutions that lead the way in your industry.
          </Description>
        </OurWorkContent>
        <OurWorkImage src={work} alt="Our Work" />
      </OurWorkSection>

      <Section ref={ref}>
        <ProjectsGrid
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.1
              }
            }
          }}
        >
          {displayedProjects.map((project, index) => (
            <ProjectCard
              key={index}
              custom={index}
              variants={cardVariants}
            >
              <ProjectImage src={project.image} alt={project.title} />
              <ProjectContent>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectDescription expanded={expandedDescriptions[index]}>
                  {project.description}
                </ProjectDescription>
                <DescriptionToggle onClick={() => toggleDescription(index)}>
                  <ToggleIcon 
                    src={expandedDescriptions[index] ? negative : positive} 
                    alt="Toggle Description" 
                  />
                  {expandedDescriptions[index] ? "Show Less" : "View More"}
                </DescriptionToggle>
                <TechStack>
                  {techIcons.map((icon, i) => (
                    <TechIcon key={i} src={icon} alt="Technology Icon" />
                  ))}
                </TechStack>
              </ProjectContent>
            </ProjectCard>
          ))}
        </ProjectsGrid>
        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Show Less" : "View All"}
        </ToggleButton>
      </Section>

   
<AwardsSection>
  <Title>Awards and Recognition</Title>
  <Subtitle>
    "We're proud to be recognized as a top performer by Clutch and
    Appfutura, acknowledging our expertise in IT services, software
    development, and iOS app creation."
  </Subtitle>
  <Slider {...carouselSettings}>
    <div><AwardImage src={award_1} alt="Clutch Award" /></div>
    <div><AwardImage src={award_2} alt="Top Software Development Company" /></div>
    <div><AwardImage src={award_3} alt="Top App Developers" /></div>
    <div><AwardImage src={award_4} alt="Top Mobile App Developers" /></div>
    <div><AwardImage src={award_5} alt="Top Rated App Development Company" /></div>
    <div><AwardImage src={award_6} alt="Top Rated App Development" /></div>
    <div><AwardImage src={award_5} alt="Top Rated Software Development Company" /></div>
  </Slider>
</AwardsSection>

<BusinessesSection>
  <Title>Why Businesses Prefer Us?</Title>
  <Subtitle>
    As the leading IT company, Jethi Tech Solutions guarantees client
    satisfaction through surveys, punctual delivery, established SLAs
    for support, superior quality in deliverables, and a commendable
    client retention record
  </Subtitle>
  <BusinessesGrid>
    {businessReasons.map((item, index) => (
      <BusinessItem key={index}>
        <BusinessIcon src={item.icon} alt={item.text} />
        <BusinessName>{item.text}</BusinessName>
      </BusinessItem>
    ))}
  </BusinessesGrid>
</BusinessesSection>

<TestimonialSection>
  <Title>Our Customers love what we do</Title>
  <Subtitle>See what our clients say about our development services</Subtitle>
  <TestimonialCard
    onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}
  >
    <TestimonialImage src={Customers} alt="Customer" />
    <TestimonialContent>
      <TestimonialText>{testimonials[activeDot].text}</TestimonialText>
      <TestimonialAuthor>{testimonials[activeDot].author}</TestimonialAuthor>
      <TestimonialPosition>{testimonials[activeDot].position}</TestimonialPosition>
      <AwardsDots>
        {testimonials.map((_, index) => (
          <AwardsDot
            key={index}
            active={index === activeDot}
            onClick={() => setActiveDot(index)}
          />
        ))}
      </AwardsDots>
    </TestimonialContent>
  </TestimonialCard>
</TestimonialSection>


      <TrustedSection>
        <Title>Trusted By 100+ Happy Brands</Title>
        <Description>
          At Jethi Tech Solutions, we are dedicated to driving continuous innovation and digital transformation for our stakeholders.
        </Description>
        <Slider {...carouselSettings}>
          <TrustedLogo src={trusted_partner_1} alt="Partner 1" />
          <TrustedLogo src={trusted_partner_2} alt="Partner 2" />
          <TrustedLogo src={trusted_partner_3} alt="Partner 3" />
          <TrustedLogo src={trusted_partner_4} alt="Partner 4" />
          <TrustedLogo src={trusted_partner_5} alt="Partner 5" />
          <TrustedLogo src={trusted_partner_6} alt="Partner 6" />
          <TrustedLogo src={trusted_partner_7} alt="Partner 7" />
          <TrustedLogo src={trusted_partner_8} alt="Partner 8" />
        </Slider>
      </TrustedSection>
    </Container>
  );
};

export default Work;