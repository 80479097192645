import React from "react";
import styled from "styled-components";

const CodeOfConduct = () => {
  return (
    <Container>
      <Header>Code of Conduct</Header>
      <LastUpdated>Last updated: September 20, 2024</LastUpdated>
      <Content>
        <Section>
          <Paragraph>
            At Jethi Tech Solutions (JTS), we are committed to fostering an
            environment built on mutual respect, professionalism, and
            innovation. Our Code of Conduct reflects our dedication to ethical
            business practices and a culture of inclusivity. The following
            principles guide our actions and interactions within our team, with
            partners, clients, and the community.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>1. Integrity and Ethics</SectionTitle>
          <UnorderedList>
            <ListItem>
              <strong>Honesty</strong>: We conduct business with transparency,
              truthfulness, and integrity.
            </ListItem>
            <ListItem>
              <strong>Ethical Behavior</strong>: We expect all employees and
              partners to maintain the highest ethical standards in all
              dealings, rejecting any form of bribery, corruption, or dishonest
              practices.
            </ListItem>
          </UnorderedList>
        </Section>

        <Section>
          <SectionTitle>2. Respect and Inclusivity</SectionTitle>
          <UnorderedList>
            <ListItem>
              <strong>Diversity and Inclusion</strong>: We value and respect
              diversity in our workplace and are committed to fostering an
              inclusive environment where everyone is welcomed and treated with
              fairness.
            </ListItem>
            <ListItem>
              <strong>Zero Tolerance for Discrimination</strong>: Harassment or
              discrimination based on race, gender, age, religion, sexual
              orientation, or any other protected characteristic is not
              tolerated.
            </ListItem>
            <ListItem>
              <strong>Respect for Others</strong>: All employees, clients, and
              stakeholders are to be treated with respect and professionalism.
            </ListItem>
          </UnorderedList>
        </Section>

        <Section>
          <SectionTitle>3. Professional Conduct</SectionTitle>
          <UnorderedList>
            <ListItem>
              <strong>Collaboration</strong>: We strive to create an open and
              collaborative environment where all team members are encouraged to
              contribute, share ideas, and communicate effectively.
            </ListItem>
            <ListItem>
              <strong>Accountability</strong>: Employees must take ownership of
              their responsibilities and deliver work that meets our standards
              of excellence and reliability.
            </ListItem>
            <ListItem>
              <strong>Workplace Safety</strong>: We promote a healthy, safe, and
              harassment-free work environment for everyone.
            </ListItem>
          </UnorderedList>
        </Section>

        <Section>
          <SectionTitle>4. Confidentiality</SectionTitle>
          <UnorderedList>
            <ListItem>
              <strong>Data Protection</strong>: Employees must protect
              confidential information related to JTS, our clients, partners,
              and suppliers. Any unauthorized disclosure is strictly prohibited.
            </ListItem>
            <ListItem>
              <strong>Privacy</strong>: We are committed to upholding the
              privacy rights of all our stakeholders and maintaining data
              security in accordance with legal and ethical standards.
            </ListItem>
          </UnorderedList>
        </Section>

        <Section>
          <SectionTitle>5. Compliance with Laws</SectionTitle>
          <UnorderedList>
            <ListItem>
              <strong>Adherence to Regulations</strong>: We abide by all
              applicable local, national, and international laws governing our
              business operations and industry standards.
            </ListItem>
            <ListItem>
              <strong>Intellectual Property</strong>: We respect the
              intellectual property rights of others and expect our employees to
              do the same.
            </ListItem>
          </UnorderedList>
        </Section>

        <Section>
          <SectionTitle>6. Commitment to Excellence</SectionTitle>
          <UnorderedList>
            <ListItem>
              <strong>Continuous Learning</strong>: We encourage our employees
              to pursue continuous improvement, innovation, and skill
              enhancement to keep pace with industry advancements.
            </ListItem>
            <ListItem>
              <strong>Quality Service</strong>: We strive to deliver top-quality
              products and services that exceed customer expectations.
            </ListItem>
          </UnorderedList>
        </Section>

        <Section>
          <SectionTitle>7. Conflict of Interest</SectionTitle>
          <UnorderedList>
            <ListItem>
              <strong>Disclosure of Conflicts</strong>: Employees must disclose
              any personal interests or relationships that may create a conflict
              with the interests of JTS.
            </ListItem>
            <ListItem>
              <strong>Fair Dealing</strong>: All business decisions should be
              based on the best interests of the company and its clients,
              without any undue influence from personal relationships or gains.
            </ListItem>
          </UnorderedList>
        </Section>

        <Section>
          <SectionTitle>8. Reporting Violations</SectionTitle>
          <UnorderedList>
            <ListItem>
              <strong>Open Communication</strong>: We encourage employees to
              speak up if they witness or experience any misconduct or unethical
              behavior. Reporting channels are confidential and non-retaliatory.
            </ListItem>
            <ListItem>
              <strong>Commitment to Resolution</strong>: Any reported issues
              will be addressed promptly, impartially, and in accordance with
              company policies.
            </ListItem>
          </UnorderedList>
        </Section>

        <Section>
          <SectionTitle>Final Words</SectionTitle>
          <Paragraph>
            By adhering to this Code of Conduct, we create an organization that
            is ethical, inclusive, and driven by excellence. Every team member,
            partner, and collaborator shares the responsibility to uphold our
            values and ensure that Jethi Tech Solutions remains a great place to
            work and a trusted partner for our clients.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  max-width: 65vw;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;

const Header = styled.h1`
  text-align: center;
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 2rem;
    margin-top: 50px;
  }
`;

const LastUpdated = styled.p`
  text-align: center;
  color: #666;
  font-size: 1rem;
  margin-bottom: 10px;
`;

const Content = styled.div`
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Section = styled.section`
  margin-bottom: 10px;
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  font-size: 1.8rem;
  margin-bottom: 15px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
  color: #333;
`;

const UnorderedList = styled.ul`
  margin-bottom: 15px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

export default CodeOfConduct;
