import React from "react";
import styled from "styled-components";

const RefundPolicy = () => {
  return (
    <Container>
      <Header>Refund Policy</Header>
      <LastUpdated>Last updated: September 20, 2024</LastUpdated>
      <Content>
        <Section>
          <Paragraph>
            At Jethi Tech Solutions, we are committed to delivering high-quality
            products and services tailored to the needs of our clients. Before
            engaging our services, we strive to ensure that all project
            requirements and deliverables are clearly understood by both
            parties. Therefore, it is important to note that we do not offer
            refunds for any payments made for our products or services.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>Why We Do Not Offer Refunds:</SectionTitle>
          <UnorderedList>
            <ListItem>
              <strong>Customized Solutions</strong>: Our services and products
              are often customized or tailored to the specific needs of our
              clients, making it difficult to reallocate resources or reverse
              delivered work.
            </ListItem>
            <ListItem>
              <strong>Commitment of Resources</strong>: Once a project is
              initiated, substantial resources, including time, personnel, and
              technology, are dedicated to delivering the agreed-upon services.
            </ListItem>
            <ListItem>
              <strong>Transparent Communication</strong>: We work closely with
              our clients through every stage of the project. Our processes are
              designed to be transparent, collaborative, and to ensure clients'
              requirements are met effectively.
            </ListItem>
          </UnorderedList>
        </Section>

        <Section>
          <SectionTitle>Client Satisfaction Commitment</SectionTitle>
          <Paragraph>
            While we do not provide refunds, your satisfaction is of utmost
            importance to us. We are fully committed to resolving any concerns
            or issues you may encounter during your engagement with us. Our team
            is available to discuss and address any challenges, make
            adjustments, or find mutually agreeable solutions where feasible.
          </Paragraph>
          <Paragraph>
            If you have any questions or require assistance, please reach out to
            our support team at{" "}
            <EmailLink href="mailto:sales@jethitech.com">
              sales@jethitech.com
            </EmailLink>
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>Note</SectionTitle>
          <Paragraph>
            By engaging in our services, you acknowledge and agree to this No
            Refund Policy. Please make sure you fully understand our terms
            before proceeding with any transactions.
          </Paragraph>
          <Paragraph>Thank you for choosing Jethi Tech Solutions.</Paragraph>
        </Section>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  max-width: 65vw;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;

const Header = styled.h1`
  text-align: center;
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 2rem;
    margin-top: 50px;
  }
`;

const LastUpdated = styled.p`
  text-align: center;
  color: #666;
  font-size: 1rem;
  margin-bottom: 10px;
`;

const Content = styled.div`
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Section = styled.section`
  margin-bottom: 10px;
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  font-size: 1.8rem;
  margin-bottom: 15px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
  color: #333;
`;

const UnorderedList = styled.ul`
  margin-bottom: 15px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const EmailLink = styled.a`
  color: #3498db;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default RefundPolicy;
