import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import phone from "../assets/images/phone.svg";
import mail from "../assets/images/mail.svg";
import github from "../assets/images/githubblack.png";
import instagram from "../assets/images/instagramblack.png";
import linkedin from "../assets/images/linkedinblack.png";
import facebook from "../assets/images/facebookblack.png";
import twitter from "../assets/images/twitterblack.png";

const CardContainer = styled.div`
  @media (min-width: 769px) {
    width: 350px;
    background-color: #ddeeff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    gap: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    // width: 100%;
    background-color: #ddeeff;
    padding: 20px;
    // height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
    gap:0px;
    box-shadow: none;
    transform: translateX(0);
  }
`;

const NavSection = styled.div`
  margin-bottom: 20px;
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    background-color: #f5f8ff;
    border-radius: 8px;
    padding: 10px;
    margin: 0 0 15px 0;
  }
`;

const NavButton = styled.button`
  width: 100%;
  padding: 12px 15px;
  text-align: left;
  background: none;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  color: #434343;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #015bcc;
    color: white;
    border-radius: 4px;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    padding: 12px 15px;
    margin: 2px 0;
  }
`;

const Section = styled.div``;

const Title = styled.h2`
  border-left: 2px solid #434343;
  font-size: 24px;
  font-weight: bold;
  color: #434343;
  margin: 0 0 5px 0;
  cursor: pointer;
  padding-left: 7px;
  transition: all 0.3s ease;
  
  &:hover {
    color: #007bff;
    border-left: 2px solid #007bff;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: 10px;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #6b6f82;
  margin-bottom: 15px;
  margin-left: 9px;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;

  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #434343;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  a {
    color: #434343;
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: #007bff;
    }
  }
`;

const Icon = styled.img`
  margin-right: 10px;
  width: 20px;
  height: 20px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 12px 25px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const SocialLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

const SocialIcon = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
`;

const FooterSection = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 15px;
    background-color: #f5f5f5;
    margin-top: auto;
  }
`;

const FooterSection2 = styled.div`
  padding: 2px;
  border-radius: 10px;
  text-align: center;
`;

const FooterText = styled.p`
  font-size: 12px;
  color: #6b6f82;
  margin: 5px 0;

  a {
    color: #007bff;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterLink = styled.a`
  font-size: 14px;
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
const MenuCard = ({ onQuoteClick, onProfileClick, onNavigate, isMobile, onClose }) => {
  const navigate = useNavigate();

  const handleItemClick = (path) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClose) onClose();
    if (onNavigate) {
      onNavigate(path);
    } else {
      navigate(path);
    }
  };

  const handleButtonClick = (type) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClose) onClose();
    if (type === 'quote' && onQuoteClick) {
      onQuoteClick();
    }
    if (type === 'profile' && onProfileClick) {
      onProfileClick();
    }
  };

  return (
    <CardContainer>
      {isMobile && (
        <NavSection>
          <NavButton onClick={handleItemClick('/services')}>
            Services
          </NavButton>
          <NavButton onClick={handleItemClick('/technology')}>
            Technology
          </NavButton>
          <NavButton onClick={handleItemClick('/hire')}>
            Hire
          </NavButton>
          <NavButton onClick={handleItemClick('/work')}>
            Work
          </NavButton>
        </NavSection>
      )}

      <Section>
        <div onClick={handleItemClick('/about')}>
          <Title>About Us</Title>
          <Subtitle>Know who we are</Subtitle>
        </div>
        <div onClick={handleItemClick('/blog')}>
          <Title>Blog</Title>
          <Subtitle>Latest information and updates</Subtitle>
        </div>
        <div onClick={handleItemClick('/contact')}>
          <Title>Contact Us</Title>
          <Subtitle>Get in touch with us</Subtitle>
        </div>
      </Section>

      <ContactInfo>
        <ContactItem>
          <Icon src={phone} alt="Phone" />
          <a href="tel:+917976625313">+91 79766 25313</a>
        </ContactItem>
        <ContactItem>
          <Icon src={mail} alt="Mail" />
          <a href="mailto:sales@jethitech.com">sales@jethitech.com</a>
        </ContactItem>
      </ContactInfo>

      <Button onClick={handleButtonClick('quote')}>
        Get a Quote
      </Button>

      <SocialIcons>
        <SocialLink href="https://www.facebook.com/jethitech/" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={facebook} alt="Facebook" />
        </SocialLink>
        <SocialLink href="https://www.instagram.com/jethitech/" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={instagram} alt="Instagram" />
        </SocialLink>
        <SocialLink href="https://github.com/jethitech" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={github} alt="Github" />
        </SocialLink>
        <SocialLink href="https://www.linkedin.com/company/jethitech" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={linkedin} alt="Linkedin" />
        </SocialLink>
        <SocialLink href="https://twitter.com/jethitech?lang=en" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={twitter} alt="Twitter" />
        </SocialLink>
      </SocialIcons>

      <FooterSection2>
        <FooterText>
          © 2023, All Rights Reserved by{" "}
          <a href="https://jethitech.com/" target="_blank" rel="noopener noreferrer">
            Jethitech
          </a>
        </FooterText>
      </FooterSection2>

      <FooterSection>
        <FooterText>We deliver value with information</FooterText>
        <FooterLink onClick={handleButtonClick('profile')}>
          EXPLORE CORPORATE PROFILE →
        </FooterLink>
      </FooterSection>
    </CardContainer>
  );
};

export default MenuCard;