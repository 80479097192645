import React from 'react';

const NotFound = () => {
  
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white px-4">
      <div className="max-w-3xl w-full text-center">
        {/* Custom SVG Illustration */}
        <svg 
          viewBox="0 0 800 400" 
          className="w-full max-w-2xl mx-auto mb-8"
        >
          {/* Background Circles */}
          <circle cx="400" cy="200" r="150" fill="#F5F8FF" />
          <circle cx="400" cy="200" r="100" fill="#E8F0FF" />
          
          {/* 404 Text */}
          <text 
            x="400" 
            y="220" 
            fontSize="120" 
            fontWeight="bold" 
            fill="#015BCC" 
            textAnchor="middle" 
            fontFamily="Poppins"
          >
            404
          </text>
          
          {/* Decorative Elements */}
          <g>
            {/* Circuit Lines */}
            <path 
              d="M 200,200 Q 250,150 300,200 T 400,200 T 500,200 T 600,200" 
              fill="none" 
              stroke="#2F69B1" 
              strokeWidth="2" 
              strokeDasharray="5,5"
            />
            <circle cx="200" cy="200" r="5" fill="#015BCC" />
            <circle cx="300" cy="200" r="5" fill="#015BCC" />
            <circle cx="400" cy="200" r="5" fill="#015BCC" />
            <circle cx="500" cy="200" r="5" fill="#015BCC" />
            <circle cx="600" cy="200" r="5" fill="#015BCC" />
            
            {/* Broken Connection Symbol */}
            <path 
              d="M 380,150 L 420,250" 
              stroke="#FF4444" 
              strokeWidth="3" 
              strokeLinecap="round"
            />
            <path 
              d="M 420,150 L 380,250" 
              stroke="#FF4444" 
              strokeWidth="3" 
              strokeLinecap="round"
            />
          </g>
          
          {/* Floating Tech Elements */}
          <g className="tech-elements">
            <rect x="250" y="100" width="20" height="20" fill="#015BCC" opacity="0.5" transform="rotate(45, 260, 110)" />
            <rect x="530" y="280" width="20" height="20" fill="#2F69B1" opacity="0.5" transform="rotate(45, 540, 290)" />
            <circle cx="180" cy="280" r="10" fill="#015BCC" opacity="0.5" />
            <circle cx="620" cy="120" r="10" fill="#2F69B1" opacity="0.5" />
          </g>
        </svg>
        
   
        
      </div>
    </div>
  );
};

export default NotFound;