import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { X } from "lucide-react";
import about_as from "../assets/images/about_as.png";
import finding_image from "../assets/images/finding_image.svg";
import startup from "../assets/images/startup.svg";
import smbs from "../assets/images/smbs.svg";
import enterprise from "../assets/images/enterprise.svg";
import agency from "../assets/images/agency.svg";
import Customers from "../assets/images/Customers.png";
import CountUp from "react-countup";
import find_1 from "../assets/images/find_1.png";
import find_2 from "../assets/images/find_2.png";
import find_3 from "../assets/images/find_3.png";
import find_4 from "../assets/images/find_4.png";
import find_5 from "../assets/images/find_5.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AboutWrapper = styled.div`
  font-family: "Poppins";
`;

const Section = styled.section`
  padding: 20px 0;
  @media (max-width: 768px) {
    padding: 15px 0;
    width: 100%;
    overflow-x: hidden;
  }
`;

const Container = styled.div`
  max-width: 65vw;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px;
    overflow-x: hidden;
    margin-top: 60px;
  }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const HeroSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
`;

const HeroContent = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    order: 2;
    margin-top: 20px;
  }
`;

const HeroTitle = styled.h1`
  font-size: 48px;
  color: #434343;
  margin-bottom: 20px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 1.3;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const Button = styled.button`
  background-color: #015bcc;
  color: white;
  border: none;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(to right, #0072ff 0%, #2f69b1 100%);
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 15px 20px;
    font-size: 16px;
  }
`;

const ConsultButton = styled(Button)`
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const HeroImage = styled.img`
  flex: 1;
  max-width: 40%;
  @media (max-width: 768px) {
    order: 1;
    max-width: 80%;
    margin-bottom: 20px;
  }
`;

const BeliefSection = styled(Section)`
  text-align: center;
  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const BeliefTitle = styled.h2`
  font-size: 46px;
  background: linear-gradient(
    270deg,
    #000000 60%,
    #1a1a1a 70%,
    #0072ff 85%,
    #2f69b1 100%
  );
  background-size: 200% 200%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${gradientAnimation} 3s ease infinite;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  line-height: 62.4px;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 1.3;
    padding: 0 10px;
  }
`;

const BeliefText = styled.p`
  font-size: 18px;
  color: #666;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.5;
    padding: 0 15px;
  }
`;

const VisionMissionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const VisionMissionCard = styled(motion.div)`
  background-color: #fff;
  box-shadow: 7px 0px 9px 0px rgba(190, 190, 190, 0.25),
    0px 4px 5px 0px rgba(190, 190, 190, 0.25);
  padding: 40px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const CardImage = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const CardTitle = styled.h3`
  font-size: 24px;
  color: #434343;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const CardText = styled.p`
  font-size: 16px;
  color: #666;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.5;
  }
`;

const SolutionsSection = styled(Section)`
  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const SolutionsSubtitle = styled.p`
  font-size: 18px;
  color: #666;
  margin-bottom: 40px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 16px;
    padding: 0 15px;
  }
`;

const SolutionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const SolutionItem = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  &:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const SolutionIcon = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 20px;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
    margin: 0 0 15px 0;
  }
`;

const SolutionContent = styled.div`
  flex: 1;
`;

const SolutionTitle = styled.h4`
  font-size: 20px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const SolutionDescription = styled.p`
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ValueSection = styled(Section)`
  text-align: center;
  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const ValueSubtitle = styled.p`
  font-size: 20px;
  color: #666;
  margin-bottom: 40px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 16px;
    padding: 0 15px;
  }
`;

const StatsGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 15px;
  }
`;

const StatItem = styled(motion.div)`
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const StatNumber = styled.h3`
  font-size: 36px;
  color: #2f2f2f;
  margin-bottom: 10px;
  border-bottom: 1px solid #5c5c5c;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const StatLabel = styled.p`
  font-size: 16px;
  color: #666;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CustomerTestimonial = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #6c6c6c;
  border-radius: 60px;
  padding: 40px;
  margin-top: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    border-radius: 30px;
    margin: 25px 15px 0;
  }
`;

const TestimonialImage = styled.img`
  width: 20%;
  margin-right: 40px;
  @media (max-width: 768px) {
    width: 50%;
    margin: 0 0 20px 0;
    display: none;
  }
`;

const TestimonialContent = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const TestimonialText = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TestimonialAuthor = styled.h4`
  font-size: 18px;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const TestimonialPosition = styled.p`
  font-size: 14px;
  color: #666;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(90deg, #2f69b1 0%, #0072ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 500px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 95%;
    padding: 20px;
    margin: 0 10px;
  }
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  &:hover {
    opacity: 0.7;
  }
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const ModalForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ModalInput = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: "Poppins";
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

const ModalSelect = styled.select`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: "Poppins";
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

const ModalSubmitButton = styled.button`
  background-color: #015bcc;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Poppins";
  margin: 10px auto 0;
  display: block;
  width: fit-content;
  &:hover {
    background: linear-gradient(to right, #0072ff 0%, #2f69b1 100%);
  }
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 25px;
    font-size: 14px;
  }
`;

const SuccessModal = styled(ModalOverlay)``;

const SuccessContent = styled(ModalContent)`
  text-align: center;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 15px;
  }
`;

const BottomText = styled.p`
  font-size: 20px;
  color: #434343;
  text-align: left;
  flex: 1;
  line-height: 30px;
  font-weight: 700;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const FindUsSection = styled(Section)`
  text-align: center;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const FindUsSubtitle = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    padding: 0 15px;
  }
`;

const CarouselContainer = styled(motion.div)`
  display: flex;
  white-space: nowrap;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const CarouselItem = styled(motion.div)`
  flex: 0 0 auto;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 15px;
  }
`;

const ReviewPlatform = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 200px;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    width: 150px;
    padding: 15px;
  }
`;

const PlatformLogo = styled.img`
  margin-bottom: 10px;
  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    max-width: 120px;
  }
`;

const AwardsDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
`;

const AwardsDot = styled.span`
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#434343" : "#6C6C6C")};
  border-radius: 50%;
`;

const cardVariants2 = {
  hiddenLeft: { opacity: 0, x: -100 },
  hiddenRight: { opacity: 0, x: 100 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.2,
      duration: 0.8,
    },
  }),
};
const TechnologiesSection = styled(Section)`
  padding: 0;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    padding: 20px 0;
    margin-bottom: 30px;
  }
`;

const Title = styled.h2`
  font-size: 46px;
  background: linear-gradient(
    270deg,
    #000000 60%,
    #1a1a1a 70%,
    #0072ff 85%,
    #2f69b1 100%
  );
  background-size: 200% 200%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${gradientAnimation} 3s ease infinite;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  line-height: 62.4px;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 40.4px;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;
const reviewVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.3,
      duration: 0.5,
    },
  }),
};

const About = () => {
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeDot, setActiveDot] = useState(0);
  const { ref: refValue, inView: inViewValue } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const testimonials = [
    {
      text: "Jethi Tech Solutions has been a game-changer for our business. Their team delivered a top-notch solution that exceeded our expectations. We're thrilled with the results!",
      author: "Mark Rise",
      position: "CEO, Founder",
    },
    {
      text: "Since implementing Jethi Tech Solutions, we've seen a 25% increase in website traffic and a 15% boost in conversions. The team at Jethi Tech Solutions truly understands our business needs.",
      author: "Jane Doe",
      position: "Marketing Director",
    },
    {
      text: "Working with Jethi Tech Solutions felt like a true partnership. They were responsive, proactive, and always willing to go the extra mile. We highly recommend their services.",
      author: "John Smith",
      position: "CEO, Founder",
    },
  ];

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      budget: e.target.budget.value,
      interest: e.target.interest.value,
      source: e.target.source.value,
      type: "quote",
    };

    try {
      await fetch(
        "https://script.google.com/macros/s/AKfycbyJArJogatRAhXwPtRE8J5PnXovdBG_c4M5VgBS8l3euz1dwHjOXum1GI4a6TndzpGK/exec",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      setShowSuccess(true);
      setShowQuoteModal(false);
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AboutWrapper>
      <Container>
        <HeroSection>
          <HeroContent>
            <HeroTitle>
              The right{" "}
              <GradientText>
                technology   partner
              </GradientText>{" "}
              can change
                everything
            </HeroTitle>
            <HeroSubtitle>
              We are firm believers that the best software comes from  
              small agile and highly skilled teams.
            </HeroSubtitle>
            <ConsultButton onClick={() => setShowQuoteModal(true)}>
              Consult Now
            </ConsultButton>
          </HeroContent>
          <HeroImage src={about_as} alt="About Us" />
        </HeroSection>

        <BeliefSection>
          <BeliefTitle>Our Belief System</BeliefTitle>
          <BeliefText>
            At our core, we believe technology is the key to unlocking a
            business's true potential. We design software that empowers users,
              not intimidates them, prioritizing both human-centered design
            and cutting-edge functionality. We're a team that thrives on
            continuous
              learning and exploration, constantly seeking ways to push the
            boundaries of what software can achieve. Collaboration is our fuel –
            diverse
              perspectives coming together to craft exceptional solutions.
            Building trust with our clients is paramount, fostering open
            communication and transparency
              to ensure the solutions we deliver are exactly what they
            need. This is more than just developing software; it's about shaping{" "}
             
            the future through innovation and strong partnerships.
          </BeliefText>
          <VisionMissionGrid>
            <VisionMissionCard
              custom={0}
              initial="hiddenLeft"
              whileInView="visible"
              variants={cardVariants2}
              viewport={{ once: true }}
            >
              <CardImage src={finding_image} alt="Our Vision" />
              <CardTitle>Our Vision</CardTitle>
              <CardText>
                Our vision is to become a trailblazer in IT development, shaping
                the future with software that is both innovative and
                user-centric. We see a world where technology seamlessly
                integrates with businesses, empowering them through powerful yet
                intuitive software solutions. We are committed to pushing the
                boundaries of what's possible, constantly seeking new
                advancements that will redefine the role of software in driving
                business success.
              </CardText>
            </VisionMissionCard>
            <VisionMissionCard
              custom={1}
              initial="hiddenRight"
              whileInView="visible"
              variants={cardVariants2}
              viewport={{ once: true }}
            >
              <CardImage src={finding_image} alt="Our Mission" />
              <CardTitle>Our Mission</CardTitle>
              <CardText>
                Our mission is to empower businesses through exceptional
                software solutions. We craft custom software that exceeds
                expectations and delivers tangible business value by leveraging
                the latest technologies and a collaborative approach. We
                envision a future where technology seamlessly integrates with
                businesses by creating software that is not only powerful but
                also intuitive and user-friendly. We strive to be a pioneering
                force in IT development, shaping the future with innovative and
                user-centric software, constantly pushing the boundaries of
                what's possible.
              </CardText>
            </VisionMissionCard>
          </VisionMissionGrid>
        </BeliefSection>

        <SolutionsSection>
          <BeliefTitle>
            Avail Profit-Driven Development Solutions
             
            to Resolve All-Scale Business Needs
          </BeliefTitle>
          <SolutionsSubtitle>
            Solminica creates enterprise solutions that seamlessly blend
            traditional methods with the latest innovations.
          </SolutionsSubtitle>
          <SolutionsGrid>
            <SolutionItem
              custom={0}
              initial="hiddenLeft"
              whileInView="visible"
              variants={cardVariants2}
              viewport={{ once: true }}
            >
              <SolutionIcon src={smbs} alt="SMBs" />
              <SolutionContent>
                <SolutionTitle>SMBs</SolutionTitle>
                <SolutionDescription>
                  Seamlessly turn your small business into a well-known brand
                  with the top development assistance. For decades, we have been
                  helping small-scale businesses earn.
                </SolutionDescription>
              </SolutionContent>
            </SolutionItem>
            <SolutionItem
              custom={1}
              initial="hiddenRight"
              whileInView="visible"
              variants={cardVariants2}
              viewport={{ once: true }}
            >
              <SolutionIcon src={startup} alt="Startups" />
              <SolutionContent>
                <SolutionTitle>Startups</SolutionTitle>
                <SolutionDescription>
                  Let your startups climb the ladder of success in no time. We
                  offer software development services for startups and help them
                  get more funding as a brand.
                </SolutionDescription>
              </SolutionContent>
            </SolutionItem>
            <SolutionItem
              custom={2}
              initial="hiddenLeft"
              whileInView="visible"
              variants={cardVariants2}
              viewport={{ once: true }}
            >
              <SolutionIcon src={enterprise} alt="Enterprises" />
              <SolutionContent>
                <SolutionTitle>Enterprises</SolutionTitle>
                <SolutionDescription>
                  Seek robust and customised enterprise mobility solutions to
                  meet your business needs. Our each development layer is
                  engineered with experience and expertise.
                </SolutionDescription>
              </SolutionContent>
            </SolutionItem>
            <SolutionItem
              custom={3}
              initial="hiddenRight"
              whileInView="visible"
              variants={cardVariants2}
              viewport={{ once: true }}
            >
              <SolutionIcon src={agency} alt="Agencies" />
              <SolutionContent>
                <SolutionTitle>Agencies</SolutionTitle>
                <SolutionDescription>
                  Our technical approach makes it accessible for offshore
                  agencies to get exposure, improve business' financial growth,
                  and increase sales & marketing potential.
                </SolutionDescription>
              </SolutionContent>
            </SolutionItem>
          </SolutionsGrid>
        </SolutionsSection>

        <ValueSection ref={refValue}>
          <BeliefTitle>
            Solminica Deliver   Value for Money
          </BeliefTitle>
          <ValueSubtitle>
            Solminica delivers unparalleled value for money by offering
            top-quality, innovative technology solutions at competitive prices.
            Our customized services are designed to fit any budget, ensuring
            efficiency and productivity enhancements for businesses of all
            sizes. With exceptional customer support, we are your trusted
            partner in achieving technological success.
          </ValueSubtitle>
          <StatsGrid
            initial={{ opacity: 0, y: 50 }}
            animate={inViewValue ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, staggerChildren: 0.1 }}
          >
            {[
              { end: 99, suffix: "%", label: "◾ Satisfied Clients" },
              { end: 160, suffix: "+", label: "◾ Tech Enthusiasts" },
              { end: 30, suffix: "+", label: "◾ Industries covered" },
              { end: 550, suffix: "+", label: "◾ Projects Delivered" },
              {
                end: 80,
                suffix: "%+",
                label: "◾ Impressive growth of Product",
              },
              { end: 3.4, suffix: "M+", label: "◾ Lines of Code" },
              {
                end: 20,
                suffix: "+",
                label: "◾ Industry Certified Team members",
              },
              { end: 850, suffix: "+", label: "◾ On time project delivery." },
            ].map((stat, index) => (
              <StatItem
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={inViewValue ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5 }}
              >
                <StatNumber>
                  <CountUp
                    start={inViewValue ? 0 : null}
                    end={stat.end}
                    suffix={stat.suffix}
                    duration={2.5}
                  />
                </StatNumber>
                <StatLabel>{stat.label}</StatLabel>
              </StatItem>
            ))}
          </StatsGrid>
          <BottomRow>
            <BottomText>
              Our expertise in designing and building digital solutions
              authorizes us to develop bespoke solutions
            </BottomText>
            <ConsultButton>Consult Now!</ConsultButton>
          </BottomRow>
        </ValueSection>

        <TechnologiesSection>
          <Container>
            <Title>Our Customers love what we do</Title>
            <Subtitle>
              Our customers are at the center of everything we do, and we're
              passionate about delivering exceptional solutions  
              that exceed their expectations. We take pride in providing
              innovative services that delight and satisfy their needs,  
              fostering long-term relationships built on trust, quality, and
              reliability. Our dedication to customer satisfaction is  
              unwavering, and we continually strive to improve and refine our
              offerings to meet their evolving needs.
            </Subtitle>
            <CustomerTestimonial>
              <TestimonialImage src={Customers} alt="Customer Testimonial" />
              <TestimonialContent>
                <TestimonialText>
                  {testimonials[activeDot].text}
                </TestimonialText>
                <TestimonialAuthor>
                  {testimonials[activeDot].author}
                </TestimonialAuthor>
                <TestimonialPosition>
                  {testimonials[activeDot].position}
                </TestimonialPosition>
                <AwardsDots>
                  {testimonials.map((_, index) => (
                    <AwardsDot
                      key={index}
                      active={index === activeDot}
                      onClick={() => setActiveDot(index)}
                    />
                  ))}
                </AwardsDots>
              </TestimonialContent>
            </CustomerTestimonial>
          </Container>
        </TechnologiesSection>

        <FindUsSection>
          <BeliefTitle>Find us</BeliefTitle>
          <FindUsSubtitle>
            Discover what our team and clients are saying about us! We're proud
            to be rated   5.0 on Glassdoor and 4.9 out of 5 on Clutch.
            Explore our reviews on AmbitionBox,  
            GoodFirms, and Trustpilot to learn more about our company culture
            and
              commitment to excellence.
          </FindUsSubtitle>
          <CarouselContainer
            animate={{ x: ["0%", "-100%"] }} // Animate from start to end
            transition={{
              duration: 30, // Duration of one full scroll
              ease: "linear", // Smooth linear animation
              repeat: Infinity, // Infinite scroll
            }}
          >
            {[
              {
                src: find_1,
                alt: "AmbitionBox",
                url: "https://www.ambitionbox.com",
              },
              {
                src: find_2,
                alt: "Glassdoor",
                url: "https://www.glassdoor.com",
              },
              {
                src: find_3,
                alt: "Clutch",
                url: "https://www.clutch.co",
              },
              {
                src: find_4,
                alt: "GoodFirms",
                url: "https://www.goodfirms.co",
              },
              {
                src: find_5,
                alt: "Trustpilot",
                url: "https://www.trustpilot.com",
              },
              {
                src: find_1,
                alt: "AmbitionBox",
                url: "https://www.ambitionbox.com",
              },
              {
                src: find_2,
                alt: "Glassdoor",
                url: "https://www.glassdoor.com",
              },
              {
                src: find_3,
                alt: "Clutch",
                url: "https://www.clutch.co",
              },
              {
                src: find_4,
                alt: "GoodFirms",
                url: "https://www.goodfirms.co",
              },
              {
                src: find_5,
                alt: "Trustpilot",
                url: "https://www.trustpilot.com",
              },
            ].map((platform, index) => (
              <CarouselItem key={platform.alt}>
                <motion.a
                  href={platform.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  custom={index}
                  initial="hidden"
                  whileInView="visible"
                  variants={reviewVariants}
                  viewport={{ once: true }}
                >
                  <ReviewPlatform>
                    <PlatformLogo src={platform.src} alt={platform.alt} />
                  </ReviewPlatform>
                </motion.a>
              </CarouselItem>
            ))}
          </CarouselContainer>
        </FindUsSection>
      </Container>
      {showQuoteModal && (
        <ModalOverlay onClick={() => setShowQuoteModal(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalCloseButton onClick={() => setShowQuoteModal(false)}>
              <X />
            </ModalCloseButton>
            <ModalTitle>
              Add your information, and we will contact you.
            </ModalTitle>
            <ModalForm onSubmit={handleModalSubmit}>
              <ModalInput
                type="text"
                name="name"
                placeholder="Name*"
                required
              />
              <ModalInput
                type="email"
                name="email"
                placeholder="Email*"
                required
              />
              <ModalInput
                type="tel"
                name="phone"
                placeholder="Contact Number*"
                required
              />
              <ModalSelect name="budget" required>
                <option value="">Your Budget</option>
                <option value="0-5000">$0 - $5,000</option>
                <option value="5000-10000">$5,000 - $10,000</option>
                <option value="10000+">$10,000+</option>
              </ModalSelect>
              <ModalSelect name="interest" required>
                <option value="">Looking For</option>
                <option value="Team Extension">Team Extension</option>
                <option value="Product Engineering">Product Engineering</option>
                <option value="Web Development">Web Development</option>
                <option value="Mobility Solutions">Mobility Solutions</option>
                <option value="Emerging Tech">Emerging Tech</option>
                <option value="E-Commerce">E-Commerce</option>
                <option value="Cyber Security">Cyber Security</option>
                <option value="Cloud And Devops">Cloud And Devops</option>
                <option value="Quality Analyst">Quality Analyst</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="PR & Media">PR & Media</option>
              </ModalSelect>
              <ModalSelect name="source" required>
                <option value="">How did you learn about us</option>
                <option value="search">Search Engine</option>
                <option value="social-media">Social Media</option>
                <option value="referral">Referral</option>
                <option value="other">Other</option>
              </ModalSelect>
              <ModalSubmitButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </ModalSubmitButton>
            </ModalForm>
          </ModalContent>
        </ModalOverlay>
      )}

      {showSuccess && (
        <SuccessModal>
          <SuccessContent>
            <div style={{ textAlign: "center" }}>
              <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
                <circle cx="32" cy="32" r="32" fill="#4CAF50" />
                <path d="M20 32L28 40L44 24" stroke="white" strokeWidth="4" />
              </svg>
              <ModalTitle>Details Submitted successfully</ModalTitle>
              <ModalSubmitButton onClick={() => setShowSuccess(false)}>
                Done
              </ModalSubmitButton>
            </div>
          </SuccessContent>
        </SuccessModal>
      )}
    </AboutWrapper>
  );
};

export default About;
