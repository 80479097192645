import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import hire from "../assets/images/hire.png";
import ui from "../assets/images/ui.png";
import web from "../assets/images/web.png";
import mean from "../assets/images/mean.png";
import cross from "../assets/images/cross.png";
import desktop from "../assets/images/desktop.png";
import hire_1 from "../assets/images/hire_1.png";
import hire_2 from "../assets/images/hire_2.png";
import hire_3 from "../assets/images/hire_3.png";
import hire_4 from "../assets/images/hire_4.png";
import hire_5 from "../assets/images/hire_5.png";
import hire_6 from "../assets/images/hire_6.png";
import hire_7 from "../assets/images/hire_7.png";
import hire_8 from "../assets/images/hire_8.png";
import benefits from "../assets/images/benefits.png";
import digitalservices from "../assets/images/digitalservices.png";
import Customers from "../assets/images/Customers.png";
import blogs from "../assets/images/blogs.png";
import negative from "../assets/images/negative.png";
import positive from "../assets/images/positive.png";

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
      duration: 0.5,
    },
  }),
};

const serviceVariants = {
  hiddenLeft: { opacity: 0, x: -100 },
  hiddenRight: { opacity: 0, x: 100 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.2,
      duration: 0.8,
    },
  }),
};

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const PageWrapper = styled.div`
  font-family: "Poppins";
  color: #434343;
`;

const Section = styled.section`
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;

const Container = styled.div`
  max-width: 65vw;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px;
    margin-top: 50px;
  }
`;

const HeroSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding-top: 20px;
  }
`;

const Title = styled.h2`
  font-size: 46px;
  background: linear-gradient(
    270deg,
    #000000 60%,
    #1a1a1a 70%,
    #0072ff 85%,
    #2f69b1 100%
  );
  background-size: 200% 200%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${gradientAnimation} 3s ease infinite;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 62.4px;
  text-align: ${props => props.center ? 'center' : 'left'};
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 1.3;
    margin-bottom: 15px;
    padding: 0 20px;
    text-align: center;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: ${props => props.center ? 'center' : 'left'};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.5;
    padding: 0 20px;
    text-align: center;
  }
`;

const Button = styled.button`
  background-color: #015bcc;
  color: white;
  border: none;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  @media (min-width: 768px) {
    &:hover {
      background: linear-gradient(to right, #0072ff 0%, #2f69b1 100%);
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ViewMoreButton = styled(motion.button)`
  background-color: #015bcc;
  color: white;
  border: none;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  grid-column: span 2;
  justify-self: center;
  margin: 20px 0;
  @media (min-width: 768px) {
    &:hover {
      background: linear-gradient(to right, #0072ff 0%, #2f69b1 100%);
    }
  }
  @media (max-width: 768px) {
    grid-column: span 1;
    width: 100%;
  }
`;
const HeroContent = styled.div`
  flex: 1;
`;

const HeroImage = styled.img`
  flex: 1;
  max-width: 40%;
  @media (max-width: 768px) {
    max-width: 80%;
    margin-top: 20px;
  }
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceItem = styled(motion.div)`
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 25px;
  border-radius: 10px;
  @media (min-width: 768px) {
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
`;

const ServiceIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const ServiceTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
  color: #434343;
  font-weight: 600;
`;

const ServiceDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
`;

const ReasonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 40px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ReasonItem = styled(motion.div)`
  text-align: center;
  @media (min-width: 768px) {
    &:hover {
      transform: translateY(-5px);
    }
  }
`;

const ReasonIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const ReasonTitle = styled.h4`
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 30px;
  text-align: center;
  color: #434343;
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BenefitItem = styled(motion.div)`
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 25px;
  border-radius: 10px;
  @media (min-width: 768px) {
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
`;

const BenefitIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const BenefitTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
  color: #434343;
  font-weight: 600;
`;

const BenefitDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
`;

const HireSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const HireContent = styled.div`
  flex: 1;
  max-width: 100%;
  @media (max-width: 768px) {
    text-align: center;
    display: flex;
    flex-direction: column;
    Button {
      order: 2;
    }
  }
`;

const HireImage = styled.img`
  flex: 1;
  max-width: 25%;
  @media (max-width: 768px) {
    max-width: 80%;
    margin-bottom: 20px;
  }
`;



const TestimonialSection = styled(Section)`
  text-align: center;
`;

const TestimonialCard = styled.div`
  display: flex;
  border: 1px solid #6c6c6c;
  border-radius: 60px;
  padding: 40px;
  margin-top: 25px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    border-radius: 30px;
  }
`;

const TestimonialImage = styled.img`
  width: 20%;
  margin-right: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TestimonialContent = styled.div`
  flex: 1;
`;

const TestimonialText = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const TestimonialAuthor = styled.h4`
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 600;
`;

const TestimonialPosition = styled.p`
  font-size: 14px;
  color: #666;
`;

const AwardsDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
`;

const AwardsDot = styled.span`
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#434343" : "#6C6C6C")};
  border-radius: 50%;
`;

const FAQSection = styled(Section)``;

const FAQContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FAQContent = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const FAQItem = styled.div`
  margin-bottom: 30px;
  padding-bottom: 20px;
  text-align: left;
`;

const FAQQuestion = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #122740;
  margin-bottom: 10px;
  gap: 15px;
`;

const FAQToggle = styled.img`
  width: 54px;
  height: 54px;
  min-width: 54px;
  transition: transform 0.3s ease;
  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
`;

const FAQAnswer = styled.div`
  margin-top: 10px;
  padding-left: 69px;
  @media (max-width: 768px) {
    padding-left: 34px;
  }
`;

const FAQDescription = styled.p`
  font-size: 14px;
  color: #6b6f82;
  margin-bottom: 10px;
  line-height: 1.6;
`;

const FAQImage = styled.img`
  width: 35%;
  object-fit: contain;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ViewAllButton = styled(Button)`
  width: 30%;
  margin-top: 40px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Hire = () => {
  const navigate = useNavigate();
  const [openQuestion, setOpenQuestion] = useState(0);
  const [showAllServices, setShowAllServices] = useState(false);
  const [showAllBenefits, setShowAllBenefits] = useState(false);
  const [activeDot, setActiveDot] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe && activeDot < testimonials.length - 1) {
      setActiveDot((prev) => prev + 1);
    }
    if (isRightSwipe && activeDot > 0) {
      setActiveDot((prev) => prev - 1);
    }
    setTouchStart(null);
    setTouchEnd(null);
  };

  const services = [
    {
      icon: mean,
      title: "Mean Stack IOT Application Development",
      description: "Mean Stack is the powerhouse of platforms and software that help the developers to create fastest and slimmest IOT apps which are incredibly efficient. We also provide authentication codes that are used to check the privacy of the data."
    },
    {
      icon: web,
      title: "Mean Stack Development & Integration",
      description: "Mean Stack integrates all four top-notch technologies that are a full package for our highly and immersive experienced Mean Stack Developers."
    },
    {
      icon: ui,
      title: "Mean Stack Application Development",
      description: "The Mean Stack developers of our company are passionate to create highly optimized, robust, and extensive mobile app solutions for your business."
    },
    {
      icon: desktop,
      title: "Mean Stack E-Commerce Solutions",
      description: "E-commerce websites or apps always require flexibility. But Mean Stack is the combination of No-SQL and JS platform that gives much more flexibility while upgrading."
    },
    {
      icon: cross,
      title: "Mean Stack CMS Development",
      description: "Mean Stack developers use their extensive experience with the latest and advanced technology to create vigorous, intuitive, and efficient CMS for your business."
    }
  ];

  const benefitsData = [
    {
      icon: benefits,
      title: "Isomorphic Coding",
      description: "Mean Stack technology allows you to code shifting from one framework to another because it uses one language for the coding. So this feature makes it highly reliable."
    },
    {
      icon: benefits,
      title: "Easy Switch between Client and Server",
      description: "Mean Stack Development uses only one language for both client-side and server site. Hence, it can easily switch between Client and Server."
    },
    {
      icon: benefits,
      title: "Bug-free Development",
      description: "Our experts deliver secure as well as scalable APIs and also ensure that deliver bug-free Mean Stack Development service."
    },
    {
      icon: benefits,
      title: "Mean Stack Functionality",
      description: "Web applications always face the problem of traffic. But mean stack application is developed with Node.js technology which balances the load."
    },
    {
      icon: benefits,
      title: "Mean Stack Consulting",
      description: "Our company provides consultation facility to the clients on their doubts and issues regarding Mean Stack Apps."
    },
    {
      icon: benefits,
      title: "Cloud Integration",
      description: "MongoDB technology of Mean Stack Web Development allows developing, hosting, and testing the app in the cloud."
    }
  ];

  const testimonials = [
    {
      text: "Working with this team has been a game-changer for our development needs. Their Mean Stack expertise is unparalleled.",
      author: "Mark Rise",
      position: "CEO, Founder"
    },
    {
      text: "Outstanding technical prowess and professional service. They transformed our vision into reality.",
      author: "Sarah Johnson",
      position: "CTO"
    },
    {
      text: "Incredible attention to detail and commitment to quality. Best development team we've worked with.",
      author: "John Smith",
      position: "Product Manager"
    }
  ];

  const faqData = [
    {
      question: "What makes Mean Stack development special?",
      answer: "Mean Stack combines MongoDB, Express.js, Angular, and Node.js to provide a complete development solution that's efficient and scalable."
    },
    {
      question: "How long does a typical Mean Stack project take?",
      answer: "Project timelines vary based on complexity, but we typically deliver full applications within 2-4 months."
    },
    {
      question: "Do you provide ongoing support?",
      answer: "Yes, we offer comprehensive post-development support and maintenance services to ensure your application runs smoothly."
    }
  ];

  const visibleServices = showAllServices ? services : services.slice(0, 2);
  const visibleBenefits = showAllBenefits ? benefitsData : benefitsData.slice(0, 3);
  return (
    <PageWrapper>
      <Container>
        <HeroSection data-aos="fade-up">
          <HeroContent>
            <Title>Hire Developers to build your <span>eCommerce store</span></Title>
            <Subtitle>Transform your business with expert development services. We deliver scalable, high-performance solutions tailored to your needs.</Subtitle>
            <Button onClick={() => navigate("/contact")}>Hire Developers</Button>
          </HeroContent>
          <HeroImage src={hire} alt="Hire Developers" />
        </HeroSection>

        <Section data-aos="fade-up">
          <Title center>Our Developers' Expert Services</Title>
          <Subtitle center>Comprehensive development solutions to power your digital transformation</Subtitle>
          <ServiceGrid>
            {visibleServices.map((service, index) => (
              <ServiceItem
                key={index}
                custom={index}
                initial="hidden"
                whileInView="visible"
                variants={cardVariants}
                viewport={{ once: true }}
              >
                <ServiceIcon src={service.icon} alt={service.title} />
                <ServiceTitle>{service.title}</ServiceTitle>
                <ServiceDescription>{service.description}</ServiceDescription>
              </ServiceItem>
            ))}
            {services.length > 2 && (
              <ViewMoreButton
                onClick={() => setShowAllServices(!showAllServices)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {showAllServices ? "Show Less" : "View More"}
              </ViewMoreButton>
            )}
          </ServiceGrid>
        </Section>

        <Section data-aos="fade-up">
          <Title center>Why Hire Developers From Jethi Tech Solutions?</Title>
          <Subtitle center>Experience the difference with our expert development team</Subtitle>
          <ReasonGrid>
            {[
              { icon: hire_1, title: "Highly-skilled Team" },
              { icon: hire_2, title: "Maintain Oversight" },
              { icon: hire_3, title: "Flexible Engagement" },
              { icon: hire_4, title: "In-house Development" },
              { icon: hire_5, title: "Full Transparency" },
              { icon: hire_6, title: "IP Protection" },
              { icon: hire_7, title: "No Lock-in Period" },
              { icon: hire_8, title: "Vetted Talent" }
            ].map((reason, index) => (
              <ReasonItem
                key={index}
                custom={index}
                initial="hidden"
                whileInView="visible"
                variants={cardVariants}
                viewport={{ once: true }}
              >
                <ReasonIcon src={reason.icon} alt={reason.title} />
                <ReasonTitle>{reason.title}</ReasonTitle>
              </ReasonItem>
            ))}
          </ReasonGrid>
        </Section>

        <Section data-aos="fade-up">
          <Title center>Benefits of Mean Stack Development</Title>
          <Subtitle center>Advanced solutions with cutting-edge technology</Subtitle>
          <BenefitsGrid>
  {visibleBenefits.map((benefit, index) => (
    <BenefitItem
      key={index}
      custom={index}
      initial="hidden"
      whileInView="visible"
      variants={cardVariants}
      viewport={{ once: true }}
    >
      <BenefitIcon src={benefit.icon} alt={benefit.title} />
      <BenefitTitle>{benefit.title}</BenefitTitle>
      <BenefitDescription>{benefit.description}</BenefitDescription>
    </BenefitItem>
  ))}
</BenefitsGrid>
<div style={{ textAlign: "center", marginTop: "20px" }}>

  {benefitsData.length > 3 && (
      <ViewMoreButton
      onClick={() => setShowAllBenefits(!showAllBenefits)}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {showAllBenefits ? "Show Less" : "View More"}
    </ViewMoreButton>
  )}
        
</div>
        </Section>

        <HireSection data-aos="fade-up">
          <HireContent>
            <Title>Looking to hire an Efficient Development Team?</Title>
            <Subtitle>Get started with our expert developers today</Subtitle>
            <Button onClick={() => navigate("/contact")}>Hire now</Button>
          </HireContent>
          <HireImage src={digitalservices} alt="Hire Team" />
        </HireSection>

        <TestimonialSection data-aos="fade-up">
          <Title center>Our Customers love what we do</Title>
          <Subtitle center>See what our clients say about our development services</Subtitle>
          <TestimonialCard
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <TestimonialImage src={Customers} alt="Customer" />
            <TestimonialContent>
              <TestimonialText>{testimonials[activeDot].text}</TestimonialText>
              <TestimonialAuthor>{testimonials[activeDot].author}</TestimonialAuthor>
              <TestimonialPosition>{testimonials[activeDot].position}</TestimonialPosition>
              <AwardsDots>
                {testimonials.map((_, index) => (
                  <AwardsDot
                    key={index}
                    active={index === activeDot}
                    onClick={() => setActiveDot(index)}
                  />
                ))}
              </AwardsDots>
            </TestimonialContent>
          </TestimonialCard>
        </TestimonialSection>

        <Section data-aos="fade-up">
          <Title>FAQ</Title>
          <FAQContainer>
            <FAQContent>
              {faqData.map((item, index) => (
                <FAQItem key={index}>
                  <FAQQuestion 
                    onClick={() => setOpenQuestion(openQuestion === index ? -1 : index)}
                  >
                    <FAQToggle 
                      src={openQuestion === index ? negative : positive} 
                      alt="Toggle" 
                    />
                    {item.question}
                  </FAQQuestion>
                  {openQuestion === index && (
                    <FAQAnswer>
                      <FAQDescription>{item.answer}</FAQDescription>
                    </FAQAnswer>
                  )}
                </FAQItem>
              ))}
              <ViewAllButton onClick={() => navigate("/contact")}>View All</ViewAllButton>
            </FAQContent>
            <FAQImage src={blogs} alt="FAQ" />
          </FAQContainer>
        </Section>
      </Container>
    </PageWrapper>
  );
};

export default Hire;