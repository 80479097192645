import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from 'react-router-dom';
import styled from "styled-components";
import our_blog from "../assets/images/our_blog.png";
import { ArrowLeft, ArrowRight } from 'lucide-react';

const BlogPage = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const HeroSection1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const HeroContent = styled.div`
  flex: 1;
`;

const HeroTitle1 = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: #434343;
  line-height: 62.4px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  line-height: 45px;

  }
`;

const HeroSubtitle1 = styled.p`
  font-size: 20px;
  color: #4e4e4e;
  margin-bottom: 0;
  max-width: 600px;
  line-height: 30px;
  font-family: "poppins";
  font-weight: 400;
`;

const HeroImage = styled.img`
  flex: 1;
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ViewAllButton = styled.button`
  background: ${props => props.expanded ? '#f5f8ff' : 'linear-gradient(135deg, #015bcc 0%, #2f69b1 100%)'};
  color: ${props => props.expanded ? '#015bcc' : 'white'};
  border: ${props => props.expanded ? '2px solid #015bcc' : 'none'};
  padding: 12px 32px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 40px auto;
  display: block;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(1, 91, 204, 0.2);
  }
`;

const BlogCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
`;

const BlogImage = styled.div`
  width: 100%;
  height: 220px;
  background: ${props => props.gradient || 'linear-gradient(135deg, #015bcc 0%, #2f69b1 100%)'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const BlogContent = styled.div`
  padding: 24px;
`;

const BlogCategory = styled.span`
  color: #015bcc;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
`;

const BlogTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 12px 0;
`;

const Tag = styled.span`
  background: #f5f8ff;
  color: #015bcc;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
`;

const BlogTitle = styled.h3`
  font-size: 20px;
  color: #434343;
  margin: 12px 0;
  line-height: 1.4;
`;

const BlogMeta = styled.div`
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const FilterDropdown = styled.select`
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  color: #434343;
  min-width: 150px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;

  &:focus {
    outline: none;
    border-color: #015bcc;
  }
`;

const ResultCount = styled.p`
  color: #666;
  font-size: 16px;
  font-weight: 500;
`;

const BlogDetailsContainer = styled.div`
  max-width: 1000px;
  margin: 120px auto 60px;
  padding: 0 20px;
`;

const HeroBanner = styled.div`
  width: 100%;
  height: 300px;
  background: linear-gradient(135deg, #015bcc 0%, #2f69b1 100%);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const BlogHeader = styled.div`
  margin-bottom: 40px;
`;

const DetailsBlogTitle = styled.h1`
  font-size: 44px;
  color: #434343;
  line-height: 1.3;
  margin-bottom: 24px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const DetailsBlogMeta = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  color: #666;
  font-size: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
`;

const MetaItem = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MainContent = styled.div`
  font-size: 18px;
  line-height: 1.8;
  color: #434343;
  margin-bottom: 40px;
  font-family: "Poppins", sans-serif;
`;

const Paragraph = styled.p`
  margin-bottom: 24px;
  font-weight: 400;
`;

const SubHeading = styled.h2`
  font-size: 28px;
  color: #434343;
  margin: 40px 0 20px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const TagsSection = styled.div`
  margin: 40px 0;
  padding: 30px;
  background: #f8faff;
  border-radius: 12px;
`;

const TagsTitle = styled.h3`
  font-size: 20px;
  color: #434343;
  margin-bottom: 16px;
  font-weight: 600;
`;

const TagsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const RelatedPosts = styled.div`
  margin-top: 60px;
`;

const RelatedTitle = styled.h2`
  font-size: 32px;
  color: #434343;
  margin-bottom: 30px;
  font-weight: 700;
`;

const RelatedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const RelatedCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const RelatedImage = styled.div`
  width: 100%;
  height: 200px;
  background: ${props => props.gradient || 'linear-gradient(135deg, #015bcc 0%, #2f69b1 100%)'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RelatedContent = styled.div`
  padding: 24px;
`;

const RelatedCategory = styled.span`
  color: #015bcc;
  font-size: 14px;
  font-weight: 600;
`;

const RelatedHeading = styled.h3`
  font-size: 20px;
  color: #434343;
  margin: 12px 0;
  line-height: 1.4;
`;

const RelatedMeta = styled.div`
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  padding-top: 40px;
  border-top: 1px solid #eee;
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: #015bcc;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 0;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateX(${props => props.$isNext ? '5px' : '-5px'});
  }
`;

const blogData = [
  {
    id: 1,
    title: 'Enterprise Solutions: Building Scalable Applications',
    category: 'Development',
    tags: ['Enterprise', 'Scalability', 'Cloud'],
    date: 'November 15, 2024',
    readTime: '5 min read',
    gradient: 'linear-gradient(135deg, #015bcc 0%, #2f69b1 100%)',
    content: {
      intro: "Enterprise solution architecture requires careful consideration of scalability, security, and maintainability. Modern businesses need robust applications that can grow with their needs while maintaining performance and reliability.",
      sections: [
        {
          title: "Understanding Enterprise Architecture",
          content: "Enterprise architecture forms the backbone of modern business applications. It involves creating a structured framework that aligns technology solutions with business goals. Key considerations include scalability, security, and integration capabilities."
        },
        {
          title: "Scalability Patterns",
          content: "Implementing scalable solutions requires adopting proven architectural patterns. This includes microservices architecture, load balancing, and efficient database design. Each component must be designed to handle increased load without compromising performance."
        },
        {
          title: "Security Implementation",
          content: "Security in enterprise applications must be comprehensive, covering authentication, authorization, data encryption, and audit logging. Modern solutions implement zero-trust architecture and follow security best practices at every layer."
        }
      ]
    }
  },
  {
    id: 2,
    title: 'AI Integration in Modern Web Development',
    category: 'Technology',
    tags: ['AI', 'Innovation', 'Web Dev'],
    date: 'November 12, 2024',
    readTime: '7 min read',
    gradient: 'linear-gradient(135deg, #2f69b1 0%, #015bcc 100%)',
    content: {
      intro: "Artificial Intelligence is revolutionizing web development, enabling more intelligent and responsive applications. From chatbots to predictive analytics, AI is becoming an integral part of modern web solutions.",
      sections: [
        {
          title: "AI-Powered User Experiences",
          content: "Modern web applications leverage AI to create personalized user experiences. This includes content recommendations, smart search functionality, and adaptive interfaces that learn from user behavior."
        },
        {
          title: "Machine Learning Integration",
          content: "Integrating machine learning models into web applications requires careful architecture planning. This includes model serving, API design, and efficient data processing pipelines."
        },
        {
          title: "Future Trends",
          content: "The future of AI in web development points toward more sophisticated natural language processing, computer vision applications, and automated development tools."
        }
      ]
    }
  },
  {
    id: 3,
    title: 'Cloud Architecture Best Practices for 2024',
    category: 'Cloud',
    tags: ['AWS', 'Azure', 'DevOps'],
    date: 'November 10, 2024',
    readTime: '6 min read',
    gradient: 'linear-gradient(135deg, #015bcc 0%, #2f69b1 100%)',
    content: {
      intro: "Cloud architecture continues to evolve with new best practices emerging for cost optimization, security, and performance. Understanding these practices is crucial for building modern cloud solutions.",
      sections: [
        {
          title: "Cost Optimization Strategies",
          content: "Effective cloud cost management involves right-sizing resources, implementing auto-scaling, and utilizing reserved instances. Modern architectures must balance performance with cost-effectiveness."
        },
        {
          title: "Security Best Practices",
          content: "Cloud security requires a comprehensive approach including identity management, network security, and data protection. Implementing security at every layer is crucial for modern cloud applications."
        },
        {
          title: "Performance Optimization",
          content: "Optimizing cloud performance involves careful consideration of service selection, data locality, and caching strategies. Modern applications must deliver consistent performance across global regions."
        }
      ]
    }
  },
  {
    id: 4,
    title: 'Mobile App Development: Native vs Cross-platform',
    category: 'Development',
    tags: ['Mobile', 'React Native', 'Flutter'],
    date: 'November 8, 2024',
    readTime: '8 min read',
    gradient: 'linear-gradient(135deg, #2f69b1 0%, #015bcc 100%)',
    content: {
      intro: "The debate between native and cross-platform mobile development continues to evolve with new frameworks and tools emerging. Understanding the pros and cons of each approach is crucial for making informed development decisions.",
      sections: [
        {
          title: "Native Development Advantages",
          content: "Native development offers superior performance, deep platform integration, and access to the latest platform features. It provides the best user experience but requires platform-specific expertise."
        },
        {
          title: "Cross-Platform Solutions",
          content: "Modern cross-platform frameworks like React Native and Flutter offer near-native performance with the advantage of code sharing. They enable faster development cycles and easier maintenance."
        },
        {
          title: "Making the Right Choice",
          content: "Choosing between native and cross-platform development depends on factors like project requirements, team expertise, and long-term maintenance considerations."
        }
      ]
    }
  },
  {
    id: 5,
    title: 'Cybersecurity Measures for Enterprise Applications',
    category: 'Technology',
    tags: ['Security', 'Enterprise', 'Protection'],
    date: 'November 5, 2024',
    readTime: '6 min read',
    gradient: 'linear-gradient(135deg, #015bcc 0%, #2f69b1 100%)',
    content: {
      intro: "Enterprise application security requires a comprehensive approach to protect against evolving threats. Modern security measures must address both external and internal threats while maintaining usability.",
      sections: [
        {
          title: "Security Architecture",
          content: "A robust security architecture includes multiple layers of protection, from network security to application-level controls. Modern solutions implement zero-trust principles and continuous monitoring."
        },
        {
          title: "Threat Detection",
          content: "Advanced threat detection systems use machine learning and behavioral analytics to identify potential security breaches. Real-time monitoring and automated responses are crucial."
        },
        {
          title: "Compliance Requirements",
          content: "Enterprise applications must meet various compliance requirements including GDPR, HIPAA, and industry-specific regulations. Security measures must be designed with compliance in mind."
        }
      ]
    }
  },
  {
    id: 6,
    title: 'Microservices Architecture: A Complete Guide',
    category: 'Cloud',
    tags: ['Microservices', 'Architecture', 'Docker'],
    date: 'November 3, 2024',
    readTime: '9 min read',
    gradient: 'linear-gradient(135deg, #2f69b1 0%, #015bcc 100%)',
    content: {
      intro: "Microservices architecture has become the standard for building scalable, maintainable applications. Understanding the principles and patterns of microservices is crucial for modern application development.",
      sections: [
        {
          title: "Microservices Fundamentals",
          content: "Microservices architecture breaks down applications into small, independent services. This approach enables better scalability, maintainability, and team autonomy."
        },
        {
          title: "Implementation Patterns",
          content: "Successful microservices implementation requires patterns for service discovery, communication, and data management. Container orchestration tools like Kubernetes play a crucial role."
        },
        {
          title: "Monitoring and Operations",
          content: "Operating microservices requires sophisticated monitoring, logging, and tracing solutions. Observability is key to maintaining a healthy microservices ecosystem."
        }
      ]
    }
  }
];

export const Blog = () => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedTag, setSelectedTag] = useState('all');
  const [showAll, setShowAll] = useState(false);

  const filteredBlogs = blogData.filter(blog => {
    const categoryMatch = selectedCategory === 'all' || blog.category === selectedCategory;
    const tagMatch = selectedTag === 'all' || blog.tags.includes(selectedTag);
    return categoryMatch && tagMatch;
  });

  const visibleBlogs = showAll ? filteredBlogs : filteredBlogs.slice(0, 3);
  const allTags = [...new Set(blogData.flatMap(blog => blog.tags))];

  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (!showAll) {
      setTimeout(() => {
        window.scrollTo({
          top: window.scrollY + 400,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  return (
    <BlogPage>
      <HeroSection1>
        <HeroContent>
          <HeroTitle1>
            Our <span style={{ color: "#0000FF" }}>Blogs</span>
          </HeroTitle1>
          <HeroSubtitle1>
            Jethi Tech Solutions creates inspirational designs and robust solutions for Web and mobile apps. 
            We combine our expertise in tech & design.
          </HeroSubtitle1>
        </HeroContent>
        <HeroImage src={our_blog} alt="Our Blogs" />
      </HeroSection1>

      <FilterSection>
        <ResultCount>
          Showing {visibleBlogs.length} of {filteredBlogs.length} results
        </ResultCount>
        <FilterContainer>
          <FilterDropdown 
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="all">All Categories</option>
            <option value="Development">Development</option>
            <option value="Technology">Technology</option>
            <option value="Cloud">Cloud</option>
          </FilterDropdown>
          <FilterDropdown
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
          >
            <option value="all">All Tags</option>
            {allTags.map(tag => (
              <option key={tag} value={tag}>{tag}</option>
            ))}
          </FilterDropdown>
        </FilterContainer>
      </FilterSection>

      <BlogGrid>
        {visibleBlogs.map(blog => (
          <BlogCard key={blog.id} onClick={() => navigate(`/blog/${blog.id}`)}>
            <BlogImage gradient={blog.gradient}>
              <svg width="120" height="120" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.2"/>
                <path d="M12 6v12M6 12h12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
              </svg>
            </BlogImage>
            <BlogContent>
              <BlogCategory>{blog.category}</BlogCategory>
              <BlogTitle>{blog.title}</BlogTitle>
              <BlogTags>
                {blog.tags.map(tag => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </BlogTags>
              <BlogMeta>
                <span>{blog.date}</span>
                <span>{blog.readTime}</span>
              </BlogMeta>
            </BlogContent>
          </BlogCard>
        ))}
      </BlogGrid>

      {filteredBlogs.length > 3 && (
        <ViewAllButton 
          expanded={showAll} 
          onClick={toggleShowAll}
        >
          {showAll ? 'Collapse All' : 'View All'}
        </ViewAllButton>
      )}
    </BlogPage>
  );
};

export const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const blog = blogData.find(b => b.id === parseInt(id));
  const nextBlog = blogData.find(b => b.id === parseInt(id) + 1);
  const prevBlog = blogData.find(b => b.id === parseInt(id) - 1);

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <BlogDetailsContainer>
      <HeroBanner>
        <svg width="120" height="120" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.2"/>
          <path d="M12 6v12M6 12h12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>
      </HeroBanner>

      <BlogHeader>
        <DetailsBlogTitle>{blog.title}</DetailsBlogTitle>
        <DetailsBlogMeta>
          <MetaItem>Category: {blog.category}</MetaItem>
          <MetaItem>{blog.date}</MetaItem>
          <MetaItem>{blog.readTime}</MetaItem>
        </DetailsBlogMeta>
      </BlogHeader>

      <MainContent>
        <Paragraph>{blog.content.intro}</Paragraph>
        {blog.content.sections.map((section, index) => (
          <div key={index}>
            <SubHeading>{section.title}</SubHeading>
            <Paragraph>{section.content}</Paragraph>
          </div>
        ))}
      </MainContent>

      <TagsSection>
        <TagsTitle>Related Topics</TagsTitle>
        <TagsGrid>
          {blog.tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </TagsGrid>
      </TagsSection>

      <RelatedPosts>
        <RelatedTitle>Related Articles</RelatedTitle>
        <RelatedGrid>
          {prevBlog && (
            <RelatedCard onClick={() => navigate(`/blog/${prevBlog.id}`)}>
              <RelatedImage gradient={prevBlog.gradient}>
                <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.2"/>
                  <path d="M12 6v12M6 12h12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </RelatedImage>
              <RelatedContent>
                <RelatedCategory>{prevBlog.category}</RelatedCategory>
                <RelatedHeading>{prevBlog.title}</RelatedHeading>
                <RelatedMeta>
                  <span>{prevBlog.date}</span>
                  <span>{prevBlog.readTime}</span>
                </RelatedMeta>
              </RelatedContent>
            </RelatedCard>
          )}
          {nextBlog && (
            <RelatedCard onClick={() => navigate(`/blog/${nextBlog.id}`)}>
              <RelatedImage gradient={nextBlog.gradient}>
                <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.2"/>
                  <path d="M12 6v12M6 12h12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </RelatedImage>
              <RelatedContent>
                <RelatedCategory>{nextBlog.category}</RelatedCategory>
                <RelatedHeading>{nextBlog.title}</RelatedHeading>
                <RelatedMeta>
                  <span>{nextBlog.date}</span>
                  <span>{nextBlog.readTime}</span>
                </RelatedMeta>
              </RelatedContent>
            </RelatedCard>
          )}
        </RelatedGrid>
      </RelatedPosts>

      <NavigationButtons>
        {prevBlog && (
          <NavButton onClick={() => navigate(`/blog/${prevBlog.id}`)}>
            <ArrowLeft size={20} /> Previous Article
          </NavButton>
        )}
        {nextBlog && (
          <NavButton $isNext onClick={() => navigate(`/blog/${nextBlog.id}`)}>
            Next Article <ArrowRight size={20} />
          </NavButton>
        )}
      </NavigationButtons>
    </BlogDetailsContainer>
  );
};

export default Blog;